import { Box, Typography } from '@mui/material';
// Components
import { OnHoverToolTip, TokenAmount } from './';

const gasHoverText =
  'Transactions require a small gas fee in order to be executed on the blockchain. Gas fees are estimated based on network activity and updated automatically.';

const GasFee = ({ loading, value, token = 'ETH' }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: ' space-between',
      alignItems: 'center',
    }}
  >
    <Box display={'flex'}>
      <Typography variant="h5" sx={{ alignSelf: 'center', marginRight: '10px' }}>
        Est. Gas Fee
      </Typography>
      <OnHoverToolTip hoverText={gasHoverText} width={300} />
    </Box>
    <Typography>
      {loading ? 'Loading...' : <TokenAmount amount={value} token={token} />}
    </Typography>
  </Box>
);

export default GasFee;
