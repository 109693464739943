import { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import styles from './pageHeader.module.css';

export default function PageHeader({ headerText, showTime = false, source, children }) {
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => setDate(new Date()), 1000);

    return function cleanup() {
      clearInterval(timer);
    };
  });

  return (
    <div className={styles.wrapper}>
      <header className={styles.header}>
        <Typography variant="h2" data-core-qa={`${source}PageHeaderText`}>
          {headerText}
        </Typography>
        {showTime ? (
          <Typography variant="body2" data-core-qa={`${source}PageHeaderTime`}>
            {date.toLocaleString()}
          </Typography>
        ) : null}
      </header>
      <div className={styles.childrenWrapper}>{children}</div>
    </div>
  );
}
