import { config } from 'config';
// Utils
import { replaceNullWithFallback } from './utils';

const { webiny } = config;

const defaultLocale = 'en-US';

export async function handleFetch(query, slug) {
  try {
    const url = `${webiny.apiUrl}/${defaultLocale}`;
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${webiny.apiKey}`,
        'X-Tenant': `${webiny.tenantId}`,
      },
      body: JSON.stringify({
        query,
        variables: { slug },
      }),
    });

    const json = await res.json();

    // Return the data object from within the first key of the response
    const key = Object.keys(json.data)[0];
    return json.data[key].data;
  } catch (e) {
    console.error(`Webiny query error. Returning null and using fallback\n`, e);
    // If the API call fails, return null to use the fallback data
    return null;
  }
}

export const fetchWithFallback = (promise, fallback) =>
  promise
    .then((result) => {
      // If the result is an object, replace any null or undefined values with the fallback value
      if (typeof result === 'object') {
        const replacedResult = replaceNullWithFallback(result, fallback);
        // Combine the result with the fallback object to ensure all keys are present
        const combinedResult = { ...fallback, ...replacedResult };
        return combinedResult;
      }
      return fallback;
    })
    .catch(() => {
      return fallback;
    });

export const handleGetStaticProps = (fetchFn, fallback) => async () => {
  const disableWebiny = webiny.disable;
  if (disableWebiny) {
    return {
      props: { ...fallback },
    };
  }
  const data = await fetchWithFallback(fetchFn(), fallback);

  return {
    props: { ...data },
    revalidate: 10, // In seconds
  };
};
