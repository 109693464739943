import { Box, Button, IconButton, Typography } from '@mui/material';
import CelebrationOutlinedIcon from '@mui/icons-material/CelebrationOutlined';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// Hooks
import { useIsMobile } from '@/hooks/useIsMobile';
// Styles
import { colors } from '@/theme';
import styles from './modal-body.module.css';

export default function ModalBody({
  body,
  buttonPrimaryText,
  buttonPrimaryOnClick,
  buttonSecondaryText,
  buttonSecondaryOnClick,
  handleClose,
  header,
  displayIcon,
  showClose,
  severity,
  buttonPrimaryFullWidth = false,
  buttonSecondaryFullWidth = false,
  destructive = false,
}) {
  const isMobile = useIsMobile(820);
  const renderIcon = (severity = 'info') =>
    ({
      info: <InfoOutlinedIcon color="info" />,
      error: <ErrorOutlineOutlinedIcon color="error" />,
      warn: <ErrorOutlineOutlinedIcon color="warning" />,
      success: <CheckCircleOutlinedIcon color="success" />,
      celebrate: <CelebrationOutlinedIcon color="primary" />,
    }[severity]);

  const iconBackground = (severity) =>
    ({
      info: colors.icons.info,
      error: colors.icons.error,
      warn: colors.icons.warn,
      success: colors.icons.success,
      celebrate: colors.primary[100],
    }[severity]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.main}>
        {severity ? (
          <div className={styles.aside}>
            {displayIcon ? (
              displayIcon
            ) : (
              <Box
                sx={{
                  padding: 0,
                  width: 45,
                  height: 45,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: iconBackground(severity),
                  borderRadius: 50,
                }}
              >
                {renderIcon(severity)}
              </Box>
            )}
          </div>
        ) : null}
        <div className={styles.body}>
          <Typography variant="h3" sx={{ marginBottom: '8px', marginTop: '12px' }}>
            {header}
          </Typography>
          {showClose ? (
            <IconButton
              onClick={handleClose}
              size="small"
              sx={{
                position: 'absolute',
                right: 6,
                top: 6,
              }}
            >
              <CloseIcon color="grey" fontSize="small" />
            </IconButton>
          ) : null}
          <div className={styles.bodyText}>{body}</div>
        </div>
      </div>
      <div className={styles.buttonRow}>
        {buttonSecondaryText ? (
          <Button
            onClick={buttonSecondaryOnClick}
            variant="outlined"
            color={destructive ? 'error' : 'primary'}
            fullWidth={buttonSecondaryFullWidth || isMobile}
            data-core-qa="modalButtonSecondary"
          >
            {buttonSecondaryText}
          </Button>
        ) : null}
        {buttonPrimaryText ? (
          <Button
            onClick={buttonPrimaryOnClick}
            variant="contained"
            color={destructive ? 'error' : 'primary'}
            fullWidth={buttonPrimaryFullWidth || isMobile}
            data-core-qa="modalButtonPrimary"
          >
            {buttonPrimaryText}
          </Button>
        ) : null}
      </div>
    </div>
  );
}
