import { useState } from 'react';
import { useRouter } from 'next/router';
import { Box, Checkbox, Dialog, FormControlLabel, Typography } from '@mui/material';
// Provider
import { useAppInfo } from '@/providers/AppInfoProvider';
// Components
import { ModalBody } from '@/common/index';
// Utils
import { brand } from '@/brand/brand';
import { config } from 'config';
import { routes } from '@/routes/routes';

export default function NotificationModals({ modalData }) {
  const router = useRouter();
  const {
    handleSetRewardType,
    handleEnroll2fa,
    handleHide2fa,
    notifications,
    handleUpdateReward,
  } = useAppInfo();
  const currentModal = modalData.type;
  const [checked, setChecked] = useState(false);

  const modal = (currentModal) => {
    switch (currentModal) {
      case notifications.twoFactor:
        return {
          severity: 'info',
          header: 'Enable an Authenticator App',
          message: (
            <Box>
              <Typography variant="body1"></Typography>
              <Typography variant="body1">
                Enabling an authenticator app significantly strengthens your account
                security and helps protect your account from unauthorized access.
              </Typography>
              <FormControlLabel
                label="Don't show me this again."
                labelPlacement="end"
                control={
                  <Checkbox
                    id="loginCheckboxRememberMe"
                    data-core-qa="loginCheckboxRememberMe"
                    size="small"
                    onChange={(e) => {
                      setChecked(e.target.checked);
                    }}
                    checked={checked}
                  />
                }
              />
            </Box>
          ),
          primaryButtonText: 'Get Started',
          primaryButtonClick: () => {
            handleEnroll2fa();
            closeModal();
          },
          secondaryButtonText: 'Cancel',
          secondaryButtonClick: () => {
            handleHide2fa({ optOut: checked });
            closeModal();
          },
        };
      case notifications.wallet:
        return {
          header: 'Congratulations!',
          message: (
            <Typography variant="body1">
              You&apos;ve received a FREE {brand.brandName} wallet. Setup your wallet to
              start receiving rewards every day.
            </Typography>
          ),
          primaryButtonText: 'Set Up Wallet Now',
          primaryButtonClick: () => {
            router.push(routes.setupWallet.path);
            closeModal();
          },
          secondaryButtonText: 'Set Up Wallet Later',
          secondaryButtonClick: () => closeModal(),
        };
      case notifications.walletOffer:
        return {
          header: 'Congratulations!',
          message: (
            <Typography variant="body1">
              You qualified to receive a FREE licensed node. To start earning rewards, and
              receive your licensed node, set up your wallet and begin running your node.
            </Typography>
          ),
          primaryButtonText: 'Set Up Wallet Now',
          primaryButtonClick: () => {
            router.push(routes.setupWallet.path);
            closeModal();
          },
          secondaryButtonText: 'Set Up Wallet Later',
          secondaryButtonClick: () => closeModal(),
        };
      case notifications.referralNode:
        return {
          header: 'Congratulations!',
          message: (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
              <Typography variant="h4">
                You’ve unlocked a FREE Licensed Node and ${config.brand.offerCreditAmount}{' '}
                credit.
              </Typography>
              <Typography variant="body1">
                Set up your FREE licensed node to begin earning rewards daily. Your $
                {config.brand.offerCreditAmount} credit will automatically be loaded into
                your shopping cart to use towards the purchase of a Smart Node.
              </Typography>
            </Box>
          ),
          primaryButtonText: 'Set up my free Node!',
          primaryButtonClick: () => {
            handleUpdateReward({ rewardType: 'node-discount' });
            router.push(routes.nodes.path);
            closeModal();
          },
        };
    }
  };

  const displayData = modal(currentModal);

  const closeModal = () => {
    currentModal === notifications.referralNode &&
      handleUpdateReward({ rewardType: 'node-discount' });
    handleSetRewardType(currentModal, false, '');
  };

  return (
    <div>
      <Dialog open onClose={closeModal}>
        <ModalBody
          severity={displayData?.severity || 'celebrate'}
          showClose
          handleClose={closeModal}
          header={displayData.header}
          body={displayData.message}
          buttonPrimaryText={displayData.primaryButtonText}
          buttonPrimaryOnClick={displayData.primaryButtonClick}
          buttonSecondaryText={displayData.secondaryButtonText}
          buttonSecondaryOnClick={displayData.secondaryButtonClick}
        />
      </Dialog>
    </div>
  );
}
