export const GET_NODES_PAGE = /* GraphQL */ `
  query GetNodesPageEx {
    getNodesPageEx(where: { slug: "nodes-page" }) {
      data {
        modelId
        slug
        title
        pageHeader {
          modelId
          slug
          title
          subTitle
          primaryButton {
            modelId
            slug
            title
            helpText
            hoverText
            url
            icon
            iconAlt
          }
          secondaryButton {
            modelId
            slug
            title
            helpText
            hoverText
            url
            icon
            iconAlt
          }
        }
        smartNodeCard {
          modelId
          slug
          title
          subTitle
          toolTip {
            modelId
            slug
            title
            helpText
          }
          button {
            modelId
            slug
            title
            helpText
            url
          }
        }
        liteNodeCard {
          modelId
          slug
          title
          subTitle
          toolTip {
            modelId
            slug
            title
            helpText
            image
          }
          button {
            modelId
            slug
            title
            helpText
            hoverText
            url
            icon
            iconAlt
          }
        }
        nodeSetupCard {
          modelId
          slug
          title
          subTitle
          description
          image
          platforms {
            id
            modelId
            slug
            title
            helpText
            hoverText
            url
            icon
            iconAlt
          }
        }
      }
    }
  }
`;
