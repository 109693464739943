import * as Components from './components';
import * as ExpFlow from './experienceFlow';
import * as UiElements from './uiElements';
import * as Tables from './tables';
import * as URLs from './urls';

export const modelQueries = {
  components: {
    getHero: Components.GET_HERO,
    getImageText: Components.GET_IMAGE_TEXT,
    getNodeHosting: Components.GET_NODE_HOSTING,
    getPopUp: Components.GET_POPUP,
    getOfferPopUp: Components.GET_OFFER_POPUP,
    getProductCard: Components.GET_PRODUCT_CARD,
    getProductDetail: Components.GET_PRODUCT_DETAIL,
    getShareWithFriend: Components.GET_SHARE_WITH_FRIEND,
    getSimpleDataCard: Components.GET_SIMPLE_DATA_CARD,
  },
  experienceFlow: {
    getAccountProfile: ExpFlow.GET_ACCOUNT_PROFILE,
  },
  tables: {
    getReferralTable: Tables.GET_REFERRAL_TABLE,
    getNodesTable: Tables.GET_NODES_TABLE,
  },
  uiElements: {
    getButton: UiElements.GET_BUTTON,
    getContentCard: UiElements.GET_CONTENT_CARD,
    getMessageBanner: UiElements.GET_MESSAGE_BANNER,
    getPageHeader: UiElements.GET_PAGE_HEADER,
    getToolTip: UiElements.GET_TOOLTIP,
  },
  urls: {
    getToken: URLs.GET_TOKEN_URL,
  },
};
