export function getMinuteSecondDisplay(timeInSeconds) {
  if (timeInSeconds <= 0) {
    return `00:00`;
  }

  const minutes = Math.floor(timeInSeconds / 60);
  const remainingSeconds = timeInSeconds % 60;

  const displayMinutes = ensureDisplayTimeHasTwoDigits(minutes);
  const displaySeconds = ensureDisplayTimeHasTwoDigits(remainingSeconds);

  return `${displayMinutes}:${displaySeconds}`;
}

export function ensureDisplayTimeHasTwoDigits(time) {
  if (time < 10) {
    return '0' + time;
  }
  return time;
}
