import styled from '@emotion/styled';
import { Card } from '@mui/material';

export const StyledCard = styled(Card)`
  min-height: 200px;
  min-width: 240px;
  padding: 0px;

  .MuiCardHeader-root {
    padding: 18px;
    border-bottom: 1px solid var(--mui-palette-grey-300);
  }

  .MuiCardHeader-content {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .MuiCardContent-root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 18px;
    padding: 24px;
  }

  .MuiCardActions-root {
    padding: 0;
  }
`;
