import { useState, useEffect, createContext, useContext } from 'react';

import { getFeatureFlags } from '@/resources/feature-flag-service.resource';

const FeatureFlagContext = createContext({});

export function useFeatureFlags() {
  return useContext(FeatureFlagContext);
}

export function FeatureFlagProvider({ children }) {
  const [featureFlags, setFeatureFlags] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getFlags = async () => {
      const flagsList = await getFeatureFlags();
      // Create an object from the feature flags array and set to state.
      const globalFlags = flagsList?.data?.reduce(
        (acc, item) => ({ ...acc, [item.feature]: item.isEnabled }),
        {}
      );
      setFeatureFlags(globalFlags);
      setIsLoading(false);
    };
    getFlags();
  }, []);

  return (
    <FeatureFlagContext.Provider value={{ featureFlags, isLoading, setFeatureFlags }}>
      {children}
    </FeatureFlagContext.Provider>
  );
}
