import { useCallback, useEffect, useState } from 'react';
import { config } from 'config';
import { useAuth } from '@/hooks/useAuth';
import { useFeatureFlags } from '@/providers/FeatureFlagProvider';

export function useShowFeatureFlags() {
  const { memberData } = useAuth();
  const { featureFlags, isLoading, setFeatureFlags } = useFeatureFlags();

  const [showFeatureFlag, setShowFeatureFlag] = useState(false);

  useEffect(() => {
    let keysPressed = [];
    const keyCombo = ['Control', 'q', 'a'];

    const onKeyDown = (e) => {
      keysPressed.push(e.key);

      if (keyCombo.every((key) => keysPressed.includes(key))) {
        // Save the flags to local storage and show modal
        localStorage.setItem('core-feature-flags', JSON.stringify(featureFlags));
        setShowFeatureFlag((prevState) => !prevState);
      }
    };

    const onKeyUp = () => {
      keysPressed = [];
    };

    if (config.environment !== 'production' || memberData?.canToggleFlags) {
      document.addEventListener('keydown', onKeyDown);
      document.addEventListener('keyup', onKeyUp);
    }

    return () => {
      document.removeEventListener('keydown', onKeyDown);
      document.removeEventListener('keyup', onKeyUp);
    };
  }, [featureFlags]);

  useEffect(() => {
    // If prod and no permission to see flag modal, remove local storage flags to prevent user issues
    if (config.environment === 'production' && !isLoading) {
      localStorage.removeItem('core-feature-flags');
    } else {
      if (Object.keys(featureFlags).length > 0) {
        const localFlags = JSON.parse(localStorage.getItem('core-feature-flags') ?? '{}');
        // Allow local flags to over ride backend settings for testing if Not prod or if user has permission to toggle locally.
        const flags =
          config.environment !== 'production' || memberData?.canToggleFlags
            ? {
                ...featureFlags,
                ...localFlags,
              }
            : featureFlags;

        setFeatureFlags(flags);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const handleCloseFeatureFlag = useCallback(() => {
    setShowFeatureFlag(false);
  }, []);

  return { setShowFeatureFlag, showFeatureFlag, handleCloseFeatureFlag };
}
