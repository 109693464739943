import { useState, useCallback, useEffect } from 'react';

export function useIsOnline() {
  const [isOnline, setIsOnline] = useState(true);

  const updateOnlineStatus = useCallback(
    (e) => {
      setIsOnline(e.type === 'online');
    },
    [setIsOnline]
  );

  useEffect(() => {
    window.addEventListener('offline', updateOnlineStatus);
    window.addEventListener('online', updateOnlineStatus);

    return () => {
      window.removeEventListener('offline', updateOnlineStatus);
      window.removeEventListener('online', updateOnlineStatus);
    };
  }, [updateOnlineStatus]);

  return isOnline;
}
