export const GET_TOKEN_URL = /* GraphQL */ `
  query GetTokenURLBySlug($slug: String!) {
    getTokenEx(where: { slug: $slug }) {
      data {
        icon
        name
        symbol
      }
    }
  }
`;
