import { Box, Button, Divider } from '@mui/material';
// Components
import { LoadingSpinner } from '@/components/common';
// Hooks
import { useIsMobile } from '@/hooks/useIsMobile';

export default function CancelContinueButtons({
  onCancel,
  onContinue,
  loading,
  disabled,
  cancelText = 'Cancel',
  continueText = 'Continue',
  mobileWidth = 720,
  showDivider = false,
  size = 'medium',
  isSubmit = false,
  fullWidth = false,
}) {
  const isMobile = useIsMobile(mobileWidth);
  const isFullWidth = fullWidth || isMobile || showDivider;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: showDivider ? 'column' : 'row',
        justifyContent: isMobile ? 'space-between' : 'flex-end',
        alignItems: 'center',
        gap: showDivider ? '10px' : '24px',
      }}
    >
      <Button
        variant="outlined"
        color="primary"
        size={size}
        onClick={onCancel}
        sx={{ minWidth: isMobile ? '100px' : '120px' }}
        fullWidth={isFullWidth}
      >
        {cancelText}
      </Button>
      {showDivider && <Divider flexItem>OR</Divider>}
      <Button
        type={isSubmit ? 'submit' : 'button'}
        variant="contained"
        color="primary"
        size={size}
        onClick={onContinue}
        disabled={loading || disabled}
        sx={{ minWidth: isMobile ? '100px' : '120px' }}
        fullWidth={isFullWidth}
      >
        {loading ? <LoadingSpinner /> : continueText}
      </Button>
    </Box>
  );
}
