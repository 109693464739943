import { useEffect, useState } from 'react';
import { Box, Grid, LinearProgress, Typography } from '@mui/material';

const PASSWORD_STRENGTH = {
  veryWeak: {
    key: 'veryWeak',
    value: 'Very Weak',
  },
  weak: {
    key: 'weak',
    value: 'Weak',
  },
  strong: {
    key: 'strong',
    value: 'Strong',
  },
  veryStrong: {
    key: 'veryStrong',
    value: 'Very Strong',
  },
};

export default function PasswordStrengthBar({
  strengthPercentage,
  labelText = `Password strength: `,
}) {
  const [passwordStrengthText, setPasswordStrengthText] = useState(
    PASSWORD_STRENGTH.veryWeak
  );
  const [barColor, setBarColor] = useState('error');

  useEffect(() => {
    let newStrengthText = PASSWORD_STRENGTH.veryWeak;
    if (strengthPercentage >= 25 && strengthPercentage < 50) {
      newStrengthText = PASSWORD_STRENGTH.weak;
      setBarColor('warning');
    } else if (strengthPercentage >= 50 && strengthPercentage < 75) {
      newStrengthText = PASSWORD_STRENGTH.strong;
      setBarColor('info');
    } else if (strengthPercentage >= 75) {
      newStrengthText = PASSWORD_STRENGTH.veryStrong;
      setBarColor('success');
    }
    setPasswordStrengthText(newStrengthText);
  }, [strengthPercentage]);

  return (
    <div>
      <Grid container alignItems="center">
        <Grid item sx={{ width: '197px' }}>
          <Box sx={{ width: '100%' }}>
            <LinearProgress
              variant="determinate"
              value={strengthPercentage}
              color={barColor}
              sx={{
                height: '8px',
                border: '1px solid grey',
                borderRadius: '50px',
                [`& .MuiLinearProgress-bar`]: {
                  borderRadius: '50px',
                },
              }}
            />
          </Box>
        </Grid>
        <Grid item>
          <Box sx={{ marginBottom: '5px', marginLeft: '8px' }}>
            <Typography
              variant="body3"
              data-core-qa="strengthBarText"
              sx={{ fontSize: '14px' }}
            >
              {labelText ? labelText : null}
              <span>{passwordStrengthText.value}</span>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
