/* 
1. agreements might come in in any order, this function sorts them. Children of a parent agreement should all be grouped together, then ordered by their 'ordinal' attribute. For example, regardless of the order they come in, we would want the sorted result to look like: 
[ parent1, parent1Child1, parent1Child2, parent2, parent2Child1, parent2Child2, etc...]

2. ordinal values can have large gaps in between them if needed to allow for future additions. For example, if a parent agreement has 3 children, instead of giving them ordinal values of 1-3, you could use 10-30 with gaps of 10 in between so that future additions could be inserted without having to re-number all of them
*/
export function sortAgreements(agreements) {
  const parentAgreements = agreements.filter((agreement) => agreement.isParent);

  let parentChildMap = {};
  parentAgreements.forEach((agreement) => {
    parentChildMap[agreement.agreementId] = {
      ...agreement,
      children: [],
    };
  });
  agreements.forEach((agreement) => {
    if (!agreement.isParent) {
      parentChildMap[agreement.parentId].children[agreement.ordinal] = agreement;
    }
  });

  let sortedAgreements = [];
  Object.values(parentChildMap).forEach((parentAgreement) => {
    const children = parentAgreement.children;
    delete parentAgreement.children;
    sortedAgreements.push(parentAgreement);
    children.forEach((childAgreement) => sortedAgreements.push(childAgreement));
  });

  return sortedAgreements;
}
