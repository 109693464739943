const apiUrl = process.env.NEXT_PUBLIC_API_URL;
const env = process.env.NEXT_PUBLIC_ENVIRONMENT;

const isLocal = env === 'local';
const isStage = env === 'stage';

export const config = {
  environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
  brand: {
    externalRoutes: process.env.NEXT_PUBLIC_EXTERNAL_ROUTES,
    liteNodeId: process.env.NEXT_PUBLIC_LITE_PRODUCT_ID,
    loginImage: process.env.NEXT_PUBLIC_LOGIN_SIGNUP_IMAGE,
    nodeEngineUrl: process.env.NEXT_PUBLIC_NODE_ENGINE_URL,
    offerCreditAmount: process.env.NEXT_PUBLIC_CREDIT_AMOUNT,
    referralLinkUrl: process.env.NEXT_PUBLIC_APP_URL,
    smartNodeId: process.env.NEXT_PUBLIC_SMART_PRODUCT_ID,
    useCoreThemeRepo: process.env.NEXT_PUBLIC_USE_CORE_THEME_REPO,
  },
  core: {
    brandAmbassadorUrl: `${apiUrl}${isLocal ? ':5101' : '/brand-ambassador'}`,
    ethConnectUrl: `${apiUrl}${isLocal ? ':5003' : '/eth-connect'}`,
    featureFlagServiceUrl: `${apiUrl}${isLocal ? ':5009' : '/feature-flags'}`,
    licensesServiceUrl: `${apiUrl}${isLocal ? ':5006' : '/licenses'}`,
    membershipServiceUrl: `${apiUrl}${isLocal ? ':5001' : '/membership'}`,
    rewardsServiceUrl: `${apiUrl}${isLocal ? ':5008' : '/rewards'}`,
    shoppingServiceUrl: `${apiUrl}${isLocal ? ':5004' : '/shopping'}`,
    walletServiceUrl: `${apiUrl}${isLocal ? ':5005' : '/wallet'}`,
    rewardStreamServiceUrl: `${apiUrl}${isLocal ? ':7777' : '/rewardStream'}`,
  },
  firebase: {
    apiKey: process.env.NEXT_PUBLIC_REACT_APP_FIREBASE_API_KEY,
    appId: process.env.NEXT_PUBLIC_REACT_APP_FIREBASE_APP_ID,
    authDomain: process.env.NEXT_PUBLIC_REACT_APP_FIREBASE_AUTH_DOMAIN,
    messagingSenderId: process.env.NEXT_PUBLIC_REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    projectId: process.env.NEXT_PUBLIC_REACT_APP_FIREBASE_PROJECT_ID,
    recaptchaKey: process.env.NEXT_PUBLIC_REACT_APP_FIREBASE_RECAPTCHA_KEY,
    storageBucket: process.env.NEXT_PUBLIC_REACT_APP_FIREBASE_STORAGE_BUCKET,
  },
  stripe: {
    publishableKey: process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY,
  },
  gtm: {
    id: process.env.NEXT_PUBLIC_GTM_ID,
  },
  marketingFunnel: {
    freeNode: process.env.NEXT_PUBLIC_MARKETING_FREE_NODES,
    signup: process.env.NEXT_PUBLIC_MARKETING_FUNNEL,
    waitlist: process.env.NEXT_PUBLIC_MARKETING_WAITLIST,
    homeShareCardImage: process.env.NEXT_PUBLIC_MARKETING_IMAGE_HOME_TOP_CARD,
    shoppingBannerImage: process.env.NEXT_PUBLIC_MARKETING_IMAGE_SHOPPING_BANNER,
  },
  webiny: {
    apiKey: process.env.NEXT_PUBLIC_WEBINY_API_KEY,
    apiUrl: `${process.env.NEXT_PUBLIC_WEBINY_API_URL}${isStage ? '/preview' : '/read'}`,
    tenantId: process.env.NEXT_PUBLIC_WEBINY_TENANT_ID,
    disable: process.env.NEXT_PUBLIC_DISABLE_WEBINY === 'true' ? true : false,
  },
};
