export const GET_ACCOUNT_PROFILE = /* GraphQL */ `
  query GetAccountProfile {
    getAccountManagementProfileEx(where: { slug: "account-management-profile" }) {
      data {
        modelId
        slug
        pageHeader {
          modelId
          slug
          title
        }
        firstName {
          modelId
          slug
          title
          errors {
            modelId
            slug
            generic
            unavailable
            invalid
            tooLong
            tooShort
            notAllowed
            required
          }
        }
        lastName {
          modelId
          slug
          title
          errors {
            modelId
            slug
            generic
            unavailable
            invalid
            tooLong
            tooShort
            notAllowed
            required
          }
        }
        displayName {
          modelId
          slug
          title
          toolTip {
            modelId
            slug
            helpText
          }
          errors {
            modelId
            slug
            generic
            unavailable
            invalid
            tooLong
            tooShort
            notAllowed
            required
          }
        }
        emailAddress {
          modelId
          slug
          title
          description
          errors {
            modelId
            slug
            generic
            unavailable
            invalid
            tooLong
            notAllowed
            required
          }
        }
        dateOfBirth {
          modelId
          slug
          title
          errors {
            modelId
            slug
            generic
            unavailable
            invalid
            required
          }
        }
        phoneNumber {
          modelId
          slug
          title
          errors {
            modelId
            slug
            generic
            unavailable
            invalid
            tooLong
            tooShort
            notAllowed
            required
          }
        }
        response {
          modelId
          slug
          error {
            modelId
            slug
            title
            description
          }
          success {
            modelId
            slug
            title
            description
          }
          systemError {
            modelId
            slug
            title
            description
          }
          serverError {
            modelId
            slug
            title
            description
          }
        }
        updateButton {
          modelId
          slug
          title
        }
        updateEmailButton {
          modelId
          slug
          title
        }
      }
    }
  }
`;
