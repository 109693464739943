import { blue, green, grey, orange, red } from '@mui/material/colors';

// MUI uses 3 base color variables to determine the default component changes based on status (hover, focused etc).  These can be custom hex colors or an MUI color import https://mui.com/material-ui/customization/color/.  To create a custom color scheme you can add a key value pair for color (ex: switch for the switch button colors), or go to the specific component and create a variant.

const colorMix = (initialColor, mixColor, percentage) => {
  return `color-mix(in oklab, ${initialColor}, ${mixColor} ${percentage}%);`;
};

const brandColors = {
  primary: process.env.NEXT_PUBLIC_PRIMARY_COLOR,
  secondary: process.env.NEXT_PUBLIC_SECONDARY_COLOR,
  primaryLight: process.env.NEXT_PUBLIC_PRIMARY_LIGHT_COLOR,
  primaryDark: process.env.NEXT_PUBLIC_PRIMARY_DARK_COLOR,
  secondaryLight: process.env.NEXT_PUBLIC_SECONDARY_LIGHT_COLOR,
  secondaryDark: process.env.NEXT_PUBLIC_SECONDARY_DARK_COLOR,
  white: '#FFFFFF',
  black: '#000000',
};

export const colors = {
  // common
  common: {
    white: brandColors.white,
    black: brandColors.black,
  },
  primary: {
    main: brandColors.primary,
    light: brandColors.primaryLight,
    dark: brandColors.primaryDark,
    50: colorMix(brandColors.primary, brandColors.white, 90),
    100: colorMix(brandColors.primary, brandColors.white, 70),
    200: colorMix(brandColors.primary, brandColors.white, 60),
    300: colorMix(brandColors.primary, brandColors.white, 30),
    400: colorMix(brandColors.primary, brandColors.white, 3),
    500: colorMix(brandColors.primary, brandColors.white, 0),
    600: colorMix(brandColors.primary, brandColors.black, 18),
    700: colorMix(brandColors.primary, brandColors.black, 30),
    800: colorMix(brandColors.primary, brandColors.black, 40),
    900: colorMix(brandColors.primary, brandColors.black, 75),
  },
  secondary: {
    main: brandColors.secondary,
    light: brandColors.secondaryLight,
    dark: brandColors.secondaryDark,
  },
  error: {
    main: red[700],
    dark: red.A700,
    light: red[200],
  },
  errorLight: {
    // Currently used in shopping for out of stock chip
    main: red[100],
    dark: red[500],
    light: red[50],
    contrastText: red[600],
  },
  info: {
    main: blue[600],
    dark: blue[800],
    light: blue[200],
  },
  success: {
    main: green[500],
    dark: green.A700,
    light: green[200],
  },
  warning: {
    main: orange[600],
    dark: orange[700],
    light: orange[200],
    900: orange[900],
  },
  grey: {
    main: grey[700],
    dark: grey[800],
    light: grey[300],
    50: grey[50],
    100: grey[100],
    200: grey[200],
    300: grey[300],
    400: grey[400],
    500: grey[500],
    600: grey[600],
    700: grey[700],
    800: grey[800],
    900: grey[900],
  },
  // Switch brand colors used for the switch pay components, please check with Switch before editing.
  switch: {
    main: '#4e8dcb',
    dark: '#3d6aa7',
    light: '#9dccec',
  },

  // Modal icon background colors
  icons: {
    info: blue[50],
    error: red[50],
    warn: orange[50],
    success: green[50],
  },

  // background colors for layers
  surfaces: {
    layerOne: grey[50], // page background
    layerTwo: '#fff', // background elevated surfaces
    layerTwoDividers: grey[300], // when a divider is used on the surface as a line break
    layerTwoBorder: grey[400],
    layerThree: '#fff', // elevated surfaces on a layerTwo
    layerThreeBorder: grey[500],
    layerThreeBorderDark: grey[800],
    layerThreeDivider: grey[300],
    hodlProgress: 'linear-gradient(90deg, #2196f3 0.21%, #9c27b0 100%)',
    hodlModalBackground: 'linear-gradient(90deg, #2196f3 0.21%, #9c27b0 100%)',
    alertComponentFill: '#E5F6FD',
  },

  text: {
    primary: grey[700],
    heading: grey[900],
    subheading: grey[800],
    body: grey[700],
    bodyDark: grey[900],
  },
};
