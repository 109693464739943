import React from 'react';
import { Button } from '@mui/material';

import styles from './infoPage.module.css';
import LottieWrapper from '@/assets/lottie/LottieWrapper/LottieWrapper';

export default function InfoPage({
  headerText,
  subText1,
  subText2,
  buttonText,
  buttonCallback,
  imageSource,
  imageAltText,
  fileSource,
  animation,
}) {
  return (
    <div className={styles.pageWrapper}>
      <h1 className={styles.header} data-core-qa="infoPageTitleText">
        {headerText}
      </h1>
      {subText1 ? (
        <p className={styles.subText} data-core-qa="infoPageSubText1">
          {subText1}
        </p>
      ) : null}
      {subText2 ? (
        <p className={styles.subText} data-core-qa="infoPageSubText2">
          {subText2}
        </p>
      ) : null}
      {buttonText ? (
        <div className={styles.buttonRow}>
          {fileSource ? (
            <a href={fileSource} download target="_blank" rel="noreferrer">
              <Button
                data-core-qa="infoPageButtonDownload"
                variant="contained"
                size="large"
                onClick={buttonCallback}
              >
                {buttonText}
              </Button>
            </a>
          ) : (
            <Button
              data-core-qa="infoPageButton"
              variant="contained"
              size="large"
              onClick={buttonCallback}
            >
              {buttonText}
            </Button>
          )}
        </div>
      ) : null}
      {imageSource ? (
        <div className={styles.mobileImageContainer}>
          <img src={imageSource} alt={imageAltText} className={styles.image} />
        </div>
      ) : null}
      {animation ? (
        <LottieWrapper animation={animation} imageStyle={styles.lottie} />
      ) : null}
    </div>
  );
}
