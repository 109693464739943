import { routes } from '@/routes/routes';
import {
  CartShoppingSolid,
  ChartLineSolid,
  CheckToSlotSolid,
  CircleNodesSolid,
  EnvelopeSolid,
  HouseSolid,
  MedalSolid,
  MegaphoneSolid,
  RightFromBracketSolid,
  StoreSolid,
  WalletSolid,
} from '@/assets/icons/iconComponents/navigation';

/**
 * @param {number} cartCount quantity of items in user cart
 * @param {number} votingCount pending voting proposals
 * @param {number} notificationCount user notifications
 * @returns {object} routing object with badge counts
 */
export const authRoutes = (navBadges, isAmbassadorAndFeatureEnabled) => {
  const baseRoutes = [
    {
      ...routes.loggedInHome,
      icon: <HouseSolid className="navIcon" />,
    },
    {
      key: 'dashboard',
      value: 'Dashboard',
      icon: <ChartLineSolid className="navIcon" />,
      subitems: [
        {
          ...routes.nodes,
          icon: <CircleNodesSolid className="navIcon" />,
          badgeContent: navBadges.nodes,
        },
        {
          ...routes.rewards,
          icon: <MedalSolid className="navIcon" />,
        },
        // Conditionally include the brandAmbassador route
        ...(isAmbassadorAndFeatureEnabled
          ? [
              {
                ...routes.brandAmbassador,
                icon: <MegaphoneSolid className="navIcon" />,
              },
            ]
          : []),
        {
          ...routes.wallet,
          icon: <WalletSolid className="navIcon" />,
          badgeContent: navBadges.wallet,
        },
      ],
    },
    {
      ...routes.store,
      icon: <StoreSolid className="navIcon" />,
    },
    {
      ...routes.cart,
      icon: <CartShoppingSolid className="navIcon" />,
      badgeContent: navBadges.cart,
    },
    {
      ...routes.voting,
      icon: <CheckToSlotSolid className="navIcon" />,
      badgeContent: navBadges.voting,
    },
    {
      ...routes.support,
      icon: <EnvelopeSolid className="navIcon" />,
    },
  ];

  return baseRoutes;
};

export const noAuthRoutes = [
  {
    ...routes.store,
    icon: <StoreSolid className="navIcon" />,
  },
  {
    ...routes.support,
    icon: <EnvelopeSolid className="navIcon" />,
  },
];

export const accountRoutes = {
  manageAccount: { ...routes.manageAccountProfile },
  logout: { ...routes.logout, icon: <RightFromBracketSolid className="navIcon" /> },
};
