export const GET_REFERRAL_TABLE = /* GraphQL */ `
  query GetReferralTableBySlugEx($slug: String!) {
    getMyReferralsTableEx(where: { slug: $slug }) {
      data {
        id
        modelId
        slug
        icon
        title
        shareNameText
        hideNameText
        shareNameCheckboxLabel
        linkText
        myReferrals {
          dateLabel
          nodesLabel
          smartNodesLabel
          nameLabel
        }
        noReferrals {
          title
          subTitle
          image
        }
      }
    }
  }
`;

export const GET_NODES_TABLE = /* GraphQL */ `
  query GetNodesTableBySlug($slug: String!) {
    getNodesTable(where: { slug: $slug }) {
      data {
        id
        modelId
        slug
        icon
        title
        description
        descriptionLinkText
        noNodes {
          title
          subTitle
        }
      }
    }
  }
`;
