import { config } from '../config';
const currentVersion = '1.0';

export function isBrandAmbassador() {
  return {
    method: 'GET',
    url: `${config.core.brandAmbassadorUrl}/v${currentVersion}/ambassadors/isAmbassador`,
  };
}

export function linkUserToBrandAmbassador(brandAmbassadorId) {
  return {
    method: 'POST',
    url: `${config.core.brandAmbassadorUrl}/v${currentVersion}/ambassadors/assign-to-ambassador/${brandAmbassadorId}`,
  };
}

export function getBrandAmbassadorReferrals({ page, pageSize }) {
  return {
    method: 'POST',
    url: `${config.core.brandAmbassadorUrl}/v${currentVersion}/ambassadors/referrals-report`,
    data: {
      page,
      pageSize,
    },
  };
}
