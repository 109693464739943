import { useCallback, useState } from 'react';
// Hooks
import { useAuth } from '@/hooks/useAuth';
// Utils
import { brand } from '@/brand/brand';
import {
  getAllUserVotes,
  getCharterById,
} from '@/resources/chain-ledger-service.resource';

const errState = {
  SERVER: 'server',
  NOT_FOUND: 'unfound',
};

export function useCharter() {
  const { memberData, fetcher } = useAuth();

  const [charter, setCharter] = useState({});
  const [hasVoted, setHasVoted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [votingEnded, setVotingEnded] = useState(false);
  const [error, setError] = useState('');

  const userId = memberData?.userId;
  //This code returns a single charter by charterKey, if you need to search an array of charters, please use getCharters api call instead.
  const getCharterInfo = useCallback(() => {
    fetcher(getCharterById({ charterKey: brand.pages.voting.charterKey }))
      .then((data) => {
        const timeNow = new Date();
        const periodEnded = data.closeDate < timeNow.toISOString();
        setCharter(data);
        if (!periodEnded) {
          getHasVoted(userId, data);
        } else {
          setVotingEnded(true);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (err?.response?.data.detail.includes('could not be found')) {
          //api call failed because the charter can't be found on chain db
          setError(errState.NOT_FOUND);
        }
        //other api call fail
        else {
          setError(errState.SERVER);
        }
        setIsLoading(false);
      });
  }, [fetcher, userId]);

  //if the user has voted, do not show charter
  const getHasVoted = useCallback(
    async (userId, charter) => {
      fetcher(getAllUserVotes({ userId }))
        .then((data) => {
          // make sure the current charter has not been voted on by this user
          const hasVoted = data.find((vote) => {
            return vote.charterId === charter.documentId;
          });
          setHasVoted(!!hasVoted);
          setIsLoading(false);
        })
        .catch(() => {
          errState(errState.SERVER);
        });
    },
    [fetcher]
  );

  return {
    charter,
    error,
    errState,
    hasVoted,
    isLoading,
    getCharterInfo,
    getHasVoted,
    setHasVoted,
    votingEnded,
  };
}
