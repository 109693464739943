import { useEffect, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
// Hooks & Utils
import { capitalize } from '../../../utils';
import { brand } from '@/brand/brand';
// Styles & Assets
import { HouseRegular } from '@/assets/icons/iconComponents';
import styles from './breadcrumb.module.css';

const RouteLabelMap = ({ filter = 'All', name = '' }) => ({
  '/': 'Home',
  '/store': 'Store',
  '/store/[filter]': `${capitalize(filter)}`,
  '/store/[filter]/[name]': `${name}`,
});

export default function Breadcrumb() {
  const router = useRouter();
  const [crumbs, setCrumbs] = useState([]);

  useEffect(() => {
    if (router.isReady) {
      const { query } = router;
      const segmentsPath = router.asPath.split('/');
      const segmentsRoute = router.route.split('/');
      const crumbLinks = buildCrumbs(segmentsPath);
      const crumbLabels = buildCrumbs(segmentsRoute);

      const crumbs = crumbLinks.map((link, index) => {
        const route = crumbLabels[index];
        const crumb = {
          link: link,
          route: route,
          label: RouteLabelMap(query)[route],
        };
        return crumb;
      });
      setCrumbs(crumbs);
    }
  }, [router.isReady, router]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.crumbWrapper}>
        <div className={styles.crumb}>
          <Link href={brand.loggedInHomeRoute}>
            <a className={styles.anchor}>
              <HouseRegular className={styles.houseIcon} />
            </a>
          </Link>
        </div>
      </div>
      {crumbs.map((c, i, { length }) => {
        if (i > 0)
          return (
            // Skip home route
            <div className={styles.crumbWrapper} key={i}>
              <div className={styles.seperator}>/</div>
              <div className={i + 1 !== length ? styles.crumb : styles.activeCrumb}>
                <Link href={c.link}>
                  <a className={styles.anchor}>{c.label}</a>
                </Link>
              </div>
            </div>
          );
      })}
    </div>
  );
}

function buildCrumbs(segments) {
  const links = segments.reduce((acc, cur, curIndex) => {
    const last = curIndex > 1 ? acc[curIndex - 1] : '';
    const newPath = `${last}/${cur}`;
    acc.push(newPath);
    return acc;
  }, []);
  return links;
}
