import { IconButton } from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { colors } from '@/theme';

export default function PasswordInputIcon({ state, setState }) {
  return (
    <IconButton
      onClick={() => setState(!state)}
      data-core-qa="passwordIconToggleButton"
      sx={{ padding: '4px' }}
    >
      {state ? (
        <VisibilityOffOutlinedIcon
          sx={{ width: '21px', height: '21px', fill: colors.grey.main }}
        />
      ) : (
        <VisibilityOutlinedIcon
          sx={{ width: '21px', height: '21px', fill: colors.grey.main }}
        />
      )}
    </IconButton>
  );
}
