import { config } from '../config';

const currentVersion = '1.0';

// returns progress of campaigns for logged in user
export function getMemberCampaigns({ id }) {
  return {
    method: 'GET',
    url: `${config.core.rewardsServiceUrl}/v${currentVersion}/members/${id}/campaigns?includeDefinitions=true`,
  };
}

// mark rewards viewed by member - array of reward ids
export function updateMemberCampaign({ id, rewardIds }) {
  return {
    method: 'POST',
    url: `${config.core.rewardsServiceUrl}/v${currentVersion}/members/${id}/rewards/viewed`,
    data: {
      memberRewardIds: rewardIds,
    },
  };
}
