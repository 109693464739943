import { createContext, useState, useEffect, useContext, useReducer } from 'react';
import * as auth from '@nerdcoresdk/nerd-core-auth';
// Hooks
import { useAuth } from '@/hooks/useAuth';
import { useCartContext } from '@/providers/CartProvider';
import { useCurrency } from '@/providers/CurrencyProvider';
import { useWalletContext } from '@/providers/WalletProvider';
// Utils
import { sortAgreements } from '@/utils/agreements';
import {
  initState,
  couponReducer,
  handlePaymentError,
  getTimerEndDate,
} from './helpers/checkoutHelpers';
import { config } from 'config';
// Resources
import {
  cancelInvoice,
  createInvoice,
  queryInvoiceStatus,
  submitInvoiceForPayment,
  getUserCoupons,
} from '@/resources/shopping-service.resource';

const Checkout = createContext({});

export const useCheckoutContext = () => useContext(Checkout);

const CheckoutProvider = ({ children }) => {
  const { chainInfo } = useWalletContext();
  const { fetcher, fetchNodePurchaseAgreements, user } = useAuth();
  const { submitUserAgreements } = auth.getInstance();
  const { cartItems, cartTotals, clearCart } = useCartContext();
  const { currency, useCreditCard } = useCurrency();

  const [checkoutState, setCheckoutState] = useState(initState.checkout);
  const [couponState, couponDispatch] = useReducer(couponReducer, initState.coupons);
  const [appliedCoupons, setAppliedCoupons] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [invoiceDetails, setInvoiceDetails] = useState(initState.invoice);
  const [pendingAgreements, setPendingAgreements] = useState([]);
  const [nodeAgreements, setNodeAgreements] = useState([]);
  const [page, setPage] = useState('/store');
  const [processingOrderState, setProcessingOrderState] = useState(initState.processing);
  const [progressSteps, setProgressSteps] = useState(initState.progressBarSteps);
  const [showWaitingModal, setShowWaitingModal] = useState(false);
  const [useExternalWallet, setUseExternalWallet] = useState(false);
  const [useMetaMask, setUseMetaMask] = useState(false);

  useEffect(() => {
    if (!user?.accessToken) {
      setCheckoutState(initState.checkout);
      couponDispatch({ type: 'CLEAR' });
      return;
    }
    handleFetchAgreements();
    handleGetCoupons();
  }, [user]);

  useEffect(() => {
    // If cart has items, verify nodes exist in cart (product type is 1) use fetched agreements, otherwise []
    if (cartItems.length) {
      const hasNodesInCart = cartItems.some(({ product }) => product.productTypeId === 1);
      hasNodesInCart ? setNodeAgreements(pendingAgreements) : setNodeAgreements([]);
    } else {
      setNodeAgreements([]);
    }
  }, [cartItems]);

  // Check if smart node is in cart and set requirements for coupon
  useEffect(() => {
    if (!couponState.couponIds.length) return;
    let smartNodeTotalUsd = 0;
    let smartNodeTitle = 'Smart Node';
    cartItems?.forEach(({ product, subtotal }) => {
      if (product.referenceProductId === config.brand.smartNodeId) {
        smartNodeTotalUsd += subtotal;
        smartNodeTitle = product.name;
      }
    });
    const smartNodeReqMet = smartNodeTotalUsd >= 2000;
    couponDispatch({
      type: 'SET_STATE',
      payload: { smartNodeReqMet, smartNodeTitle },
    });

    if (!smartNodeReqMet) clearAppliedCoupons();
  }, [cartItems, couponState.couponIds]);

  // If on processing order page, on successful payment received move to confirm screen
  useEffect(() => {
    if (currentStep !== 3) return;
    const checkInvoiceStatus = async () =>
      await fetcher(queryInvoiceStatus({ invoiceId: checkoutState.invoice.id }))
        .then((data) => {
          if (data.paidAt) {
            handleUpdateState({ invoice: data });
            setCurrentStep(4);
            setProgressSteps((prevState) => {
              const newState = [...prevState];
              newState[3] = {
                label: newState[3].label,
                status: 'complete',
              };
              return newState;
            });
            setShowWaitingModal(false);
            updateProgressBar();
            setProcessingOrderState(initState.processing);
            clearInterval(interval);
            couponDispatch({ type: 'CLEAR' });
            handleGetCoupons();
            clearAppliedCoupons();
          }
        })
        .catch((err) => {
          console.error('query error', { err });
          return clearInterval(interval);
        });

    checkInvoiceStatus();

    const interval = setInterval(checkInvoiceStatus, 30000);

    return () => {
      clearInterval(interval);
    };
  }, [currentStep]);

  const handleUpdateState = (newState) =>
    setCheckoutState((prevState) => ({
      ...prevState,
      ...newState,
    }));

  const handleSetError = (header, message) =>
    handleUpdateState({
      loading: false,
      error: { error: true, header, message },
    });

  const handleClearError = () => handleUpdateState({ error: initState.error });

  const updateStep = (direction) => {
    setCurrentStep((prevStep) => {
      if (prevStep >= 0 && prevStep <= 4) {
        return direction === 'next' ? prevStep + 1 : prevStep - 1;
      }
    });
    if (direction !== 'next') {
      const prevStep = currentStep - 1;

      setProgressSteps((prevState) => {
        return prevState.map((step, index) => {
          if (index < prevStep) return { ...step, status: 'complete' };
          if (prevStep === index) return { ...step, status: 'active' };
          if (index > prevStep) return { ...step, status: 'incomplete' };
          return step;
        });
      });
    }
  };

  const updateProgressBar = () => {
    const nextStep = currentStep + 1;

    setProgressSteps((prevState) => {
      return prevState.map((step, index) => {
        if (index < nextStep) return { ...step, status: 'complete' };
        if (nextStep === index) return { ...step, status: 'active' };
        if (index > nextStep) return { ...step, status: 'incomplete' };
        return step;
      });
    });
  };

  // ***************************** COUPONS *****************************

  const handleGetCoupons = () => {
    couponDispatch({ type: 'SET_LOADING', payload: true });

    fetcher(getUserCoupons())
      .then(({ coupons }) => {
        const couponTotalUsd = coupons.reduce(
          (total, coupon) => total + (coupon.isUsed ? 0 : coupon.value),
          0
        );

        couponDispatch({
          type: 'SET_STATE',
          payload: {
            couponTotalUsd,
            couponIds: coupons.map((coupon) => !coupon.isUsed && coupon.rewardId),
            couponTitles: coupons.map((coupon) => coupon?.promotionName),
            showBanner: !!couponTotalUsd,
          },
        });

        const storageData =
          JSON.parse(localStorage.getItem(`${user.uid}-applied-coupons`)) || [];
        const activeItems = coupons
          .map((coupon) =>
            storageData.includes(coupon.rewardId) ? coupon.rewardId : null
          )
          .filter(Boolean);
        setAppliedCoupons(activeItems.length > 0 ? activeItems : []);
      })
      .catch((err) => {
        console.error(err);
        couponDispatch({
          type: 'SET_ERROR',
          payload: {
            error: true,
            header: 'Coupon Error',
            message: 'Unable to retrieve coupons at this time.',
          },
        });
      })
      .finally(() => couponDispatch({ type: 'SET_LOADING', payload: false }));
  };

  const handleShowCouponBanner = (payload) =>
    couponDispatch({ type: 'SHOW_BANNER', payload });

  const applyCoupons = (data) => {
    setAppliedCoupons(data);
    localStorage.setItem(`${user.uid}-applied-coupons`, JSON.stringify(data));
  };

  const clearAppliedCoupons = () => {
    setAppliedCoupons([]);
    localStorage.removeItem(`${user.uid}-applied-coupons`);
  };

  // ***************************** CHECKOUT *****************************

  // Close all modals and return checkout to init
  const handleResetCheckout = () => {
    handleUpdateState(initState.checkout);
    setProgressSteps(initState.progressBarSteps);
    setProcessingOrderState(initState.processing);
    setCurrentStep(0);
    setInvoiceDetails(initState.invoice);
    setUseExternalWallet(false);
    setUseMetaMask(false);
    setShowWaitingModal(false);
  };

  // Cancel order
  const cancelOrder = () => {
    fetcher(cancelInvoice({ invoiceId: checkoutState.invoice.id })).catch((err) => {
      console.error('cancel error', { err });
    });
  };

  // Step one - fetch agreements for review
  const handleFetchAgreements = () =>
    fetchNodePurchaseAgreements({
      token: user.accessToken,
    })
      .then((agreements) => {
        setPendingAgreements(sortAgreements(agreements.unsignedAgreements));
      })
      .catch(() =>
        handleSetError(
          'System Error',
          'The agreements are unable to be viewed at this time. Please refresh the page or try again later.'
        )
      );

  // Step two - Submit agreements
  const handleSubmitAgreements = (signedForms) => {
    handleUpdateState({ loading: true });
    handleClearError();
    submitUserAgreements({ token: user.accessToken, signedForms })
      .then(() => {
        setInvoiceDetails({
          invoiceItems: cartItems,
          invoiceTotals: cartTotals,
        });
      })
      .then(() => handleCreateInvoice())
      .catch(() =>
        handleSetError(
          'System Error',
          'The agreements are unable to be submitted at this time. Please refresh the page or try again later.'
        )
      );
  };

  // Generate invoice from cart items
  const handleCreateInvoice = () => {
    handleUpdateState({ loading: true });
    handleClearError();

    const products = cartItems.map((product) => ({
      productId: product.productId,
      quantity: product.quantity,
    }));
    const canUseCoupon = couponState.smartNodeReqMet;
    // const memberDiscountCouponRewardIds = couponState.couponIds;
    fetcher(
      createInvoice({
        products,
        memberDiscountCouponRewardIds: canUseCoupon ? appliedCoupons : [],
        ...(useCreditCard && { paymentMethod: 2 }),
      })
    )
      .then((data) => {
        const { paymentTotals, invoiceETHAddress } = data;

        const paymentTotal = paymentTotals.find((total) => total.symbol === currency);

        const invoice = {
          ...data,
          totalPriceCoin: paymentTotal?.total ?? 0,
        };

        handleUpdateState({
          invoice,
          ...(useExternalWallet && {
            etherscan: {
              link: `${chainInfo?.blockExplorerUrl}/address/}${invoiceETHAddress}`,
              txnHash: invoiceETHAddress,
            },
          }),
        });
      })
      .then(() => {
        updateStep('next');
        updateProgressBar();
      })
      .catch(() => {
        handleSetError(
          'Checkout Process Error',
          'Experiencing difficulties setting up your order. Please try again later.'
        );
      })
      .finally(() => handleUpdateState({ loading: false }));
  };

  // Step three - submit payment and store transaction data
  const handleSubmitPayment = (passcode) => {
    handleUpdateState({ loading: true });
    handleClearError();
    handleSetProcessing();

    if (useExternalWallet || useCreditCard) {
      updateStep('next');
      updateProgressBar();
      clearCart();
      return;
    }

    const invoiceId = checkoutState.invoice.id;
    fetcher(submitInvoiceForPayment({ passcode, invoiceId, symbol: currency }))
      .then((etherscan) => {
        if (etherscan.success) {
          handleUpdateState({ etherscan });
          updateStep('next');
          updateProgressBar();
        } else {
          handlePaymentError(etherscan.message, handleSetError);
        }
        clearCart();
      })
      .catch(({ response }) => handlePaymentError(response?.data, handleSetError));
  };

  const handleSetProcessing = () => {
    const currentTime = new Date();
    // Set processing order timer at 15 min before modal displays
    setProcessingOrderState({
      isPending: true,
      endTime: currentTime.setMinutes(currentTime.getMinutes() + 15),
    });
  };

  // Step four - Transaction pending, watch for invoice paid update
  const viewTransactionStatus = () => {
    const url = checkoutState?.etherscan?.link;
    window.open(url, '_blank');
  };

  return (
    <Checkout.Provider
      value={{
        appliedCoupons,
        cancelOrder,
        checkoutState,
        couponState,
        currentStep,
        getTimerEndDate,
        handleShowCouponBanner,
        handleClearError,
        handleCreateInvoice,
        handleGetCoupons,
        handleUpdateState,
        handleSubmitAgreements,
        handleSubmitPayment,
        handleResetCheckout,
        invoiceDetails,
        nodeAgreements,
        page,
        processingOrderState,
        progressSteps,
        setAppliedCoupons: applyCoupons,
        setCurrentStep,
        setNodeAgreements,
        setPage,
        setProcessingOrderState,
        setShowWaitingModal,
        setUseExternalWallet,
        setUseMetaMask,
        showWaitingModal,
        updateProgressBar,
        updateStep,
        useExternalWallet,
        useMetaMask,
        viewTransactionStatus,
      }}
    >
      {children}
    </Checkout.Provider>
  );
};

export default CheckoutProvider;
