import React from 'react';
import Link from 'next/link';
import { Box, Avatar, Typography } from '@mui/material';
import NavigationItem from './NavigationItem';
// Hooks
import { useAuth } from '@/hooks/useAuth';
// Styles & Assets
import { colors } from '@/theme';

export default function AccountFooter({ accountRoutes, handleToggleDrawer }) {
  const { memberData } = useAuth();
  return (
    <Box
      sx={{
        width: '100%',
        padding: '8px',
        borderTop: `1px solid ${colors.surfaces.layerTwoDividers}`,
      }}
    >
      <Link href={accountRoutes.manageAccount.path} passHref>
        <Box
          component="a"
          sx={{
            '--parent-gap': '16px',
            width: '100%',
            padding: '8px 8px',
            display: 'flex',
            gap: 'var(--parent-gap)',
          }}
          onClick={handleToggleDrawer}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              width: 'calc(var(--drawer-width) / 4)',
            }}
          >
            {memberData?.photo.length > 0 ? (
              <Avatar src={memberData?.photo} />
            ) : (
              <Avatar sx={{ bgcolor: colors.primary[500], fontSize: '18px' }}>
                {memberData?.initials}
              </Avatar>
            )}
          </Box>
          <Box>
            <Typography variant="h5">{memberData?.fullName}</Typography>
            <Typography
              title={memberData?.email}
              variant="body2"
              sx={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                width:
                  'calc(var(--drawer-width) - (var(--drawer-width) / 4) - var(--parent-gap))',
              }}
            >
              {memberData?.email}
            </Typography>
          </Box>
        </Box>
      </Link>
      <NavigationItem
        item={accountRoutes.logout}
        handleToggleDrawer={handleToggleDrawer}
      />
    </Box>
  );
}
