import { useEffect, useRef, useState } from 'react';
import {
  Button,
  IconButton,
  Switch,
  FormControlLabel,
  TextField,
  Typography,
} from '@mui/material';
import { TrashSolid, XmarkRegular } from '@/assets/icons/iconComponents';
import styles from './featureFlagModal.module.css';
export default function FeatureFlagModal({ handleClose }) {
  const [allFeatureFlags, setAllFeatureFlags] = useState([]);
  const inputRef = useRef(null);
  const focusChild = () => {
    inputRef.current && inputRef.current.focus();
  };

  const setFeatureFlags = () => {
    const featureFlags = JSON.parse(localStorage.getItem('core-feature-flags') ?? '{}');
    const flagItems = Object.entries(featureFlags).map(([key, value]) => ({
      key,
      value,
    }));
    const sorted = flagItems.sort((a, b) => {
      if (a.key < b.key) {
        return -1;
      }
      if (a.key > b.key) {
        return 1;
      }
      return 0;
    });
    setAllFeatureFlags(sorted);
  };

  const deleteFeatureFlag = (key) => {
    const flags = JSON.parse(localStorage.getItem('core-feature-flags') ?? '{}');
    delete flags[key];
    localStorage.setItem('core-feature-flags', JSON.stringify(flags));
    setFeatureFlags();
  };

  useEffect(() => {
    setFeatureFlags();
  }, []);

  const handleCheck = (e, key) => {
    setAllFeatureFlags((prevState) => {
      return prevState.map((item) => {
        if (item.key === key) {
          const flags = JSON.parse(localStorage.getItem('core-feature-flags'));
          flags[key] = e.target.checked;
          localStorage.setItem('core-feature-flags', JSON.stringify(flags));
          return {
            key,
            value: e.target.checked,
          };
        }
        return item;
      });
    });
  };

  const handleSubmit = () => {
    const coreFeatureFlags = localStorage.getItem('core-feature-flags');
    if (coreFeatureFlags) {
      const flags = JSON.parse(coreFeatureFlags);
      flags[inputRef.current.value] = false;
      localStorage.setItem('core-feature-flags', JSON.stringify(flags));
    } else {
      localStorage.setItem(
        'core-feature-flags',
        JSON.stringify({ [inputRef.current.value]: false })
      );
    }
    inputRef.current.value = '';
    inputRef.current.focus();
    setFeatureFlags();
  };

  return (
    <article className={styles.card}>
      <div className={styles.closeButton}>
        <IconButton onClick={handleClose}>
          <XmarkRegular className={styles.closeIcon} />
        </IconButton>
      </div>
      <header>
        <Typography variant="h2">Feature Flags</Typography>
      </header>
      <section className={styles.inputSection}>
        <div htmlFor="flag" className={styles.inputRow} onClick={focusChild}>
          <TextField
            size="small"
            id="flag"
            name="flag"
            type="text"
            inputRef={inputRef}
            fullWidth
          />
        </div>
        <Button size="small" variant="contained" onClick={handleSubmit}>
          Add
        </Button>
      </section>
      <ul className={styles.gridList}>
        {allFeatureFlags.map((flag) => (
          <li className={styles.item} key={flag.key}>
            <div className={styles.container}>
              <div className={styles.switch}>
                <FormControlLabel
                  label={flag.key}
                  labelPlacement="end"
                  control={
                    <Switch
                      name={`toggle-${flag.key}`}
                      id={`toggle-${flag.key}`}
                      onChange={(e) => handleCheck(e, flag.key)}
                      checked={flag.value}
                    />
                  }
                />
              </div>
            </div>
            <IconButton onClick={() => deleteFeatureFlag(flag.key)}>
              <TrashSolid className={styles.deleteIcon} />
            </IconButton>
          </li>
        ))}
      </ul>
    </article>
  );
}
