import { Box } from '@mui/material';
import { LoadingSpinner } from './';

export default function LoadingBox() {
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '200px',
        }}
      >
        <LoadingSpinner type="dark" />
      </Box>
    </Box>
  );
}
