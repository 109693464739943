import { useState, useEffect } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const dateFormat = 'MM/DD/YYYY';

export default function DatePickerComponent({ initialValue, onChange, disabled, label }) {
  const [value, setValue] = useState(dayjs(initialValue));

  useEffect(() => {
    setValue(dayjs(initialValue));
  }, [initialValue]);

  const handleChange = (value) => {
    onChange(value.toDate());
  };

  const handleAccept = (value) => {
    onChange(value.toDate());
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        disabled={disabled}
        label={label}
        value={value}
        inputFormat={dateFormat}
        onChange={handleChange}
        onAccept={handleAccept}
        views={['year', 'month', 'day']}
        showToolbar={false}
        sx={{
          width: '100%',
        }}
        slotProps={{
          textField: {
            size: 'small',
          },
        }}
      />
    </LocalizationProvider>
  );
}
