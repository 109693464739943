import { grey } from '@mui/material/colors';
// Config
import { colors } from './colors';

export const buttonVariants = [
  // ***************************** Outlined *****************************
  {
    props: { variant: 'outlined' },
    style: {
      borderWidth: '2px',
      '&:hover': {
        borderWidth: '2px',
      },
    },
  },
  // ***************************** Text *****************************
  {
    props: { variant: 'text' },
    style: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  // ***************************** Store *****************************
  {
    props: { variant: 'store' },
    style: {
      border: `1px solid ${colors.surfaces.layerTwoDividers}`,
      backgroundColor: colors.surfaces.layerTwo,
      color: colors.surfaces.layerThreeBorderDark,
      '&:hover': {
        border: `1px solid ${colors.surfaces.layerThreeBorder}`,
        backgroundColor: colors.surfaces.layerTwo,
        color: colors.surfaces.layerThreeBorderDark,
      },
      '&:active': {
        border: `1px solid ${grey[900]}`,
        backgroundColor: colors.surfaces.layerTwoBorder,
        color: colors.surfaces.layerThreeBorderDark,
      },
      '.selected': {
        backgroundColor: grey[100],
        color: colors.surfaces.layerThreeBorderDark,
      },
    },
  },
  // ***************************** MetaMask *****************************
  {
    props: { variant: 'metaMask' },
    style: {
      color: grey[700],
      border: `1px solid ${grey[700]}`,
      backgroundColor: '#ffffff',
      '&:hover': {
        backgroundColor: '#f8dbc3',
        color: grey[700],
      },
      '&:active': {
        color: '#f6851b',
        backgroundColor: grey[900],
      },
    },
  },
  {
    props: { variant: 'metaMask', color: 'secondary' },
    style: {
      boxShadow:
        '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
      border: 'none',
      color: grey[700],
      backgroundColor: '#f8dbc3',
      '&:hover': {
        backgroundColor: '#fbbb80',
      },
    },
  },
  // ***************************** Stripe *****************************
  {
    props: { variant: 'stripe' },
    style: {
      color: '#ffffff',
      backgroundColor: '#3581ea',
      '&:hover': {
        backgroundColor: '#1970e9',
      },
    },
  },
];
