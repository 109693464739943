import { useEffect } from 'react';
import lottie from 'lottie-web';

export default function LottieWrapper({ animation, imageStyle }) {
  useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector('#sendMessage'),
      animationData: animation,
    });
  }, [animation]);

  return <div id="sendMessage" className={imageStyle} />;
}
