export const GET_BUTTON = /* GraphQL */ `
  query GetButtonBySlug($slug: String!) {
    getButton(where: { slug: $slug }) {
      data {
        id
        modelId
        slug
        title
        description
        image
      }
    }
  }
`;

export const GET_CONTENT_CARD = /* GraphQL */ `
  query GetContentCardBySlug($slug: String!) {
    getContentCardEx(where: { slug: $slug }) {
      data {
        modelId
        slug
        title
        subTitle
        description
        image
        # content(format: "html")
        button {
          modelId
          slug
          title
          helpText
          hoverText
          url
          icon
        }
      }
    }
  }
`;

export const GET_MESSAGE_BANNER = /* GraphQL */ `
  query GetMessageBannerEx($slug: String!) {
    getMessageBannerEx(where: { slug: $slug }) {
      data {
        modelId
        slug
        title
        description
        label
        icon
      }
    }
  }
`;

export const GET_PAGE_HEADER = /* GraphQL */ `
  query GetPageHeaderBySlug($slug: String!) {
    getPageHeaderEx(where: { slug: $slug }) {
      data {
        slug
        title
        subTitle
        primaryButton {
          modelId
          slug
          title
          helpText
          hoverText
          url
          icon
        }
        secondaryButton {
          modelId
          slug
          title
          helpText
          hoverText
          url
          icon
        }
      }
    }
  }
`;

export const GET_TOOLTIP = /* GraphQL */ `
  query GetToolTipBySlug($slug: String!) {
    getToolTipEx(where: { slug: $slug }) {
      data {
        id
        modelId
        slug
        title
        helpText
      }
    }
  }
`;
