import { useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
} from '@mui/material';
// Styles & Icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from './faq.module.css';

export default function FAQ({ question, answer }) {
  // To control functionality of Angle Up/Down Icons and color of AccordionSummary
  const [isExpanded, setIsExpanded] = useState(false);
  const handleChange = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Accordion
      className={styles.accordion}
      square={true}
      disableGutters={true}
      onChange={handleChange}
      sx={{
        padding: '8px',
      }}
    >
      <AccordionSummary
        className={isExpanded ? styles.summaryExpanded : null}
        expandIcon={<ExpandMoreIcon className="expandIcon" fontSize="small" />}
      >
        <Box className={styles.summaryContainer}>
          <Typography variant="h5" className={styles.typography}>
            {question}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails className={styles.details}>
        <Typography variant="body1" className={styles.answerTypography}>
          {answer}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
}
