import loginIcon from '../assets/icons/fontawesome/arrow-right-to-bracket-solid.svg';
import logoutIcon from '../assets/icons/fontawesome/arrow-right-from-bracket-solid.svg';
import userPlusIcon from '../assets/icons/fontawesome/user-plus-solid.svg';

export const authRoutes = {
  agreements: {
    path: '/agreements',
    key: 'agreements',
    value: 'Agreements',
  },
  emailCheck: {
    path: '/email-check',
    key: 'email-check',
    value: 'Email check',
  },
  emailConfirmation: {
    path: '/email-confirmation',
    key: 'email-confirmation',
    value: 'Email Confirmation',
  },
  login: {
    path: '/login',
    key: 'login',
    value: 'Log In',
    icon: loginIcon.src,
  },
  logout: {
    path: '/logout',
    key: 'logout',
    value: 'Log Out',
    icon: logoutIcon.src,
  },
  signup: {
    path: '/signup',
    key: 'signup',
    value: 'Sign Up',
    icon: userPlusIcon.src,
  },
};
