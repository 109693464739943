// This function will recursively check each key in the first object and if the value is null or undefined, it will replace it with the value from the second object.
export const replaceNullWithFallback = (cmsData, fallback) =>
  Object.entries(cmsData).reduce((acc, [key, value]) => {
    if (value === null || value === undefined) {
      acc[key] = fallback?.[key] ?? value;
    } else if (Array.isArray(value)) {
      if (value.length === 0) {
        acc[key] = fallback[key];
      } else {
        acc[key] = value.map((item) =>
          typeof item === 'object' && item !== null
            ? replaceNullWithFallback(item, fallback[key])
            : item
        );
      }
    } else if (typeof value === 'object') {
      if (value !== null) {
        acc[key] = replaceNullWithFallback(value, fallback[key]);
      } else {
        acc[key] = fallback[key];
      }
    } else {
      acc[key] = value;
    }

    return acc;
  }, {});
