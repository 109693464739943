import { useCallback } from 'react';
import axios from 'axios';

const defaultTimeout = 1000 * 60 * 1; // 1 minute

export function useFetcher({ user }) {
  const fetcher = useCallback(
    async ({ method = 'GET', url, data = null }) =>
      await axios({
        method,
        timeout: defaultTimeout,
        url,
        headers: {
          'Content-Type': 'application/json',
          ...(user?.accessToken && { Authorization: `Bearer ${user.accessToken}` }),
        },
        ...(data && { data }),
      }).then((res) => res.data),
    [user]
  );

  return fetcher;
}
