import { Filter } from 'profanity-check';
import { brand } from '../brand/brand';

const wholeName = /[a-zA-Z.'-]{2,}(?: [a-zA-Z.'-]{2,})+$/;
const singularName = /(^[a-zA-Z.' -]{2,})+$/;
const lower = /[a-z]/;
const upper = /[A-Z]/;
const number = /[0-9]/;
const symbol = /[-!@$%^&*()_+|~=#`{}[\]:";'<>?,./]/;
const noSpace = /^\S*$/;
const validEmail = /[a-zA-Z0-9_%+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,3}/;
const validPhone = /^[1-9][0-9]{7,14}$/;

export const errorMessages = {
  name: brand.errorMessages.inputValidationFields.name,
  email: brand.errorMessages.inputValidationFields.email,
  password: brand.errorMessages.inputValidationFields.password,
  confirmPassword: brand.errorMessages.inputValidationFields.confirmPassword,
  checkbox: brand.errorMessages.inputValidationFields.checkbox,
  phone: brand.errorMessages.inputValidationFields.phone,
  dob: brand.errorMessages.birthDateError,
  age: brand.errorMessages.birthDateAge,
  prohibited: 'The name you have entered is not allowed. Please try again.',
};

export function getHasValidFullName({ fullName }) {
  return wholeName.test(fullName);
}

export function getHasValidName({ name }) {
  return singularName.test(name);
}

export function getHasLowercase({ password }) {
  return lower.test(password);
}

export function getHasUppercase({ password }) {
  return upper.test(password);
}

export function getHasNumber({ password }) {
  return number.test(password);
}

export function getHasSymbol({ password }) {
  return symbol.test(password);
}

export function getHasNoSpaces({ password }) {
  return password.length === 0 ? false : noSpace.test(password);
}

export function getHasLength({ password = '', length }) {
  return password.length >= length;
}

export function getIsPasswordValid({ password }) {
  return (
    getHasLength({ password, length: 8 }) &&
    getHasLowercase({ password }) &&
    getHasUppercase({ password }) &&
    getHasNumber({ password }) &&
    getHasSymbol({ password }) &&
    getHasNoSpaces({ password })
  );
}

export function getHasValidEmail({ email }) {
  const hasNoSpace = getHasNoSpaces({ password: email });
  const isValid = validEmail.test(email);
  return hasNoSpace && isValid;
}

export function getHasValidPhone({ phone }) {
  const value = phone.replace(/[^a-zA-Z0-9]/g, '');
  return validPhone.test(value);
}

export function getHasValidDateFormat({ date }) {
  //expecting date object
  const year = new Date(date).getFullYear().toString();
  const value = date.replace(/[^a-z0-9]/g, '');
  const onlyNumbers = number.test(value);
  const error = year.length !== 4 || !onlyNumbers;
  return !error;
}

export function getHasValidAge({ date }) {
  const year = new Date(date).getFullYear().toString();
  const error =
    new Date().getFullYear() - year > 110 ||
    new Date(date).getFullYear() > new Date().getFullYear() - 18;

  return error;
}

export function getPasswordStrength({ password }) {
  let strength = 0;
  const length = password.length;

  if (length >= 14) {
    strength = strength + 60;
  } else if (length >= 12) {
    strength = strength + 45;
  } else if (length >= 10) {
    strength = strength + 35;
  } else if (length >= 8) {
    strength = strength + 9;
  }

  getHasUppercase({ password }) && (strength = strength + 10);
  getHasLowercase({ password }) && (strength = strength + 10);
  getHasSymbol({ password }) && (strength = strength + 10);
  getHasNumber({ password }) && (strength = strength + 10);

  return strength;
}

export function removeSpecialCharacters(value) {
  let updatedString;

  updatedString = value.replaceAll(/[4à@]/g, 'a');
  updatedString = updatedString.replace(/[3&€éè]/gi, 'e');
  updatedString = updatedString.replace(/[1ì!|]/gi, 'i');
  updatedString = updatedString.replace(/[0ò]/gi, 'o');
  updatedString = updatedString.replace(/[8]/gi, 'b');
  updatedString = updatedString.replace(/[$]/gi, 's');
  updatedString = updatedString.replace(/[7+]/gi, 't');
  updatedString = updatedString.replace(/[#]/gi, 'h');
  updatedString = updatedString.replace(/[©]/gi, 'c');

  return updatedString;
}

//value of entered into input, full name if needs checked, length if different
export function getIsProhibited({ value, name }) {
  const multiLanguageFilter = new Filter({ languages: ['arabic', 'english', 'french'] });
  //replace symbols
  const updatedValue = removeSpecialCharacters(value);
  const lowerCaseValue = updatedValue.toLowerCase();
  const testName = name?.replace(/[\s]+/g, '').toLowerCase();

  //check new string for profanity and other rules and return boolean
  const isProfane = multiLanguageFilter.isProfane(updatedValue);
  const isEmail = getHasValidEmail({ email: value });
  const isShort = value.length < 3;
  const hasNoSpace = getHasNoSpaces({ password: value });
  const isUserName = name ? lowerCaseValue === testName : false;
  const isUrl =
    lowerCaseValue.includes('www.') ||
    lowerCaseValue.includes('http') ||
    lowerCaseValue.includes('.com') ||
    lowerCaseValue.includes('.net') ||
    lowerCaseValue.includes('.org') ||
    lowerCaseValue.includes('.io') ||
    lowerCaseValue.includes('.app') ||
    lowerCaseValue.includes('.edu') ||
    lowerCaseValue.includes('.gov') ||
    lowerCaseValue.includes('.mil') ||
    lowerCaseValue.includes('://');
  // test string returns true if there are no repeated characters of 4 or more
  const notRepetitive = /^(?!.*(.)\1\1\1.*).*$/.test(value);

  return (
    isProfane ||
    isEmail ||
    isShort ||
    !hasNoSpace ||
    isUserName ||
    isUrl ||
    !notRepetitive
  );
}
