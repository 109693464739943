import { useState, useEffect } from 'react';

export function useIsMobile(query) {
  const querySize = query ? `(max-width: ${query}px)` : `(max-width: 767px)`;
  const value = typeof window === 'object' ? window.matchMedia(querySize).matches : false;
  const [matches, setMatches] = useState(value);

  useEffect(() => {
    const media = window.matchMedia(querySize);

    const listener = () => setMatches(media.matches);

    media.addEventListener('change', listener);

    return () => media.removeEventListener('change', listener);
  }, [query, querySize]);

  return matches;
}
