export const initError = {
  header: '',
  body: '',
};

const loadingError = {
  header: 'There was an error loading some of your NFTs',
  body: 'Please try again later.',
};

const nftState = {
  nfts: [],
  externalNfts: [],
  loading: true,
  error: initError,
  transferSuccess: false,
};

export const initNftState = {
  core: nftState,
  legacy: nftState,
  contractIds: [],
  blockchains: {},
  loading: true,
  error: initError,
  transferSuccess: false,
};

export const initNftGasState = {
  value: 0,
  loading: false,
  error: initError,
};

export const nftReducer = (state, action) => {
  switch (action.type) {
    case 'SET_LOADING':
      return {
        ...state,
        loading: action.payload,
      };
    case 'SET_CORE_NFT_STATE':
      return {
        ...state,
        core: {
          ...state.core,
          ...action.payload,
        },
      };
    case 'SET_LEGACY_NFT_STATE':
      return {
        ...state,
        legacy: {
          ...state.legacy,
          ...action.payload,
        },
      };
    case 'SET_CONTRACT_IDS':
      return {
        ...state,
        contractIds: action.payload,
      };
    case 'SET_BLOCKCHAINS':
      return {
        ...state,
        blockchains: action.payload,
      };
    case 'SET_TRANSFER_SUCCESS':
      return {
        ...state,
        transferSuccess: action.payload,
      };
    case 'SET_ERROR':
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case 'RESET':
      return {
        ...initNftState,
        blockchains: state.blockchains,
        contractIds: state.contractIds,
      };
    default:
      return state;
  }
};

export const nftErrorHandler = (cb) => (e) => {
  console.info({ e });
  cb(loadingError);
  return [];
};
