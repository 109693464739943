import { colors } from './colors';

export const typography = {
  // This font size is what determines the rem to px ratio. If this font size changes, the px values next to each rem will also change.
  fontSize: 16,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  fontFamily: 'Lato, sans-serif',
  h1: {
    fontFamily: 'Poppins, sans-serif',
    color: colors.text.heading,
    fontWeight: 500,
    fontSize: '2.5rem', // 40px
    lineHeight: '3rem', // 48px
    '@media (max-width:520px)': {
      fontSize: '2rem', // 32px
    },
  },
  h2: {
    fontFamily: 'Poppins, sans-serif',
    color: colors.text.heading,
    fontWeight: 500,
    fontSize: '2rem', // 32px
    lineHeight: '2.5rem', // 40px
    '@media (max-width:520px)': {
      fontSize: '1.5rem', // 24px
    },
  },
  h3: {
    fontFamily: 'Poppins, sans-serif',
    color: colors.text.heading,
    fontWeight: 500,
    fontSize: '1.5rem', // 24px
    lineHeight: '2rem', // 32px
    '@media (max-width:520px)': {
      fontSize: '1.125rem', // 18px
    },
  },
  h4: {
    fontFamily: 'Poppins, sans-serif',
    color: colors.text.subheading,
    fontWeight: 500,
    fontSize: '1.125rem', // 18px
    lineHeight: '1.5rem', // 24px
    '@media (max-width:520px)': {
      fontSize: '1rem', // 16px
    },
  },
  h5: {
    fontFamily: 'Poppins, sans-serif',
    color: colors.text.subheading,
    fontWeight: 500,
    fontSize: '1rem', // 16px
    lineHeight: '1.25rem', // 20px
    '@media (max-width:520px)': {
      fontSize: '.875rem', // 14px
    },
  },
  h6: {
    fontFamily: 'Poppins, sans-serif',
    color: colors.text.subheading,
    fontWeight: 500,
    fontSize: '.875rem', // 14px
    lineHeight: '1rem', // 16px
  },
  body1: {
    color: colors.text.body,
    fontSize: '1rem', // 16px
    lineHeight: '1.5rem', // 24px
    '@media (max-width:520px)': {
      fontSize: '.875rem', // 14px
    },
  },
  body1Dark: {
    color: colors.text.bodyDark,
    fontSize: '1rem', // 16px
    lineHeight: '1.5rem', // 24px
    '@media (max-width:520px)': {
      fontSize: '.875rem', // 14px
    },
  },
  body2: {
    color: colors.text.body,
    fontSize: '.875rem', // 14px
    lineHeight: '1.25rem', // 20px
  },
  body2Dark: {
    color: colors.text.bodyDark,
    fontSize: '.875rem', // 14px
    lineHeight: '1.25rem', // 20px
  },
  body3: {
    color: colors.text.body,
    fontSize: '.75rem', // 12px
    lineHeight: '1.125rem', // 18px
  },
  body3Dark: {
    color: colors.text.bodyDark,
    fontSize: '.75rem', // 12px
    lineHeight: '1.125rem', // 18px
  },
  subtitle1: {
    color: colors.text.body,
    fontWeight: 600,
    fontSize: '1.25rem', // 20px
    lineHeight: '1.5rem', // 24px
  },
  subtitle1Dark: {
    color: colors.text.bodyDark,
    fontWeight: 600,
    fontSize: '1.25rem', // 20px
    lineHeight: '1.5rem', // 24px
  },
  subtitle2: {
    color: colors.text.body,
    fontWeight: 600,
    fontSize: '1', // 16px,
    lineHeight: '1.25rem', // 20px
  },
  subtitle2Dark: {
    color: colors.text.bodyDark,
    fontWeight: 600,
    fontSize: '1', // 16px,
    lineHeight: '1.25rem', // 20px
  },
};
