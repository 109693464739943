export function buildMemberDataObj({
  authenticatorEnabled,
  canPurchaseNodes,
  email,
  memberBirthDate,
  memberDisplayName,
  memberFirstName,
  memberId,
  memberLastName,
  memberName,
  memberNumber,
  memberPhone,
  optInShareName,
  permissions,
  userId,
}) {
  const initials =
    memberFirstName && memberLastName
      ? `${memberFirstName[0]}${memberLastName[0]}`
      : `${memberName ? memberName[0] : ''}`;
  const flagPermission = permissions?.find((item) => {
    return item === 'featureflags.frontend.toggle';
  });
  return {
    memberNumber,
    optInShareName,
    userId,
    appAuthEnabled: authenticatorEnabled,
    displayName: memberDisplayName,
    dob: memberBirthDate,
    email: email,
    firstName: memberFirstName,
    fullName: memberName,
    id: memberId,
    initials: initials,
    lastName: memberLastName,
    phone: memberPhone,
    photo: '',
    canToggleFlags: flagPermission?.length > 0,
    canPurchaseNodes: canPurchaseNodes === true,
  };
}
