export const GET_HERO = /* GraphQL */ `
  query GetHeroBySlug($slug: String!) {
    getHeroModelEx(where: { slug: $slug }) {
      data {
        id
        slug
        title
        modelId
        subTitle
        description
        terms
        image
        button {
          title
          helpText
          hoverText
          url
          icon
        }
      }
    }
  }
`;

export const GET_IMAGE_TEXT = /* GraphQL */ `
  query GetImageTextBySlug($slug: String!) {
    getImageText(where: { slug: $slug }) {
      data {
        id
        modelId
        slug
        title
        description
        image
      }
    }
  }
`;

export const GET_POPUP = /* GraphQL */ `
  query GetPopUpBySlug($slug: String!) {
    getPopUpEx(where: { slug: $slug }) {
      data {
        id
        modelId
        slug
        title
        description
        image
        icon
        primaryButton {
          slug
          title
          url
          icon
          iconAlt
        }
        secondaryButton {
          slug
          title
          url
          icon
          iconAlt
        }
      }
    }
  }
`;

export const GET_OFFER_POPUP = /* GraphQL */ `
  query GetOfferPopUpBySlug($slug: String!) {
    getOfferPopupEx(where: { slug: $slug }) {
      data {
        id
        modelId
        slug
        title
        subTitle
        altTitle
        altSubTitle
        listItem {
          id
          modelId
          slug
          title
          description
          image
        }
        primaryButton {
          slug
          title
          helpText
          hoverText
          url
          icon
        }
        secondaryButton {
          slug
          title
          helpText
          hoverText
          url
          icon
        }
        socialMedia {
          id
          slug
          title
          helpText
          hoverText
          url
          icon
        }
      }
    }
  }
`;

export const GET_PRODUCT_CARD = /* GraphQL */ `
  query GetProductCardBySlug($slug: String!) {
    getProductCard(where: { slug: $slug }) {
      data {
        id
        modelId
        slug
        title
        description
        image
        button {
          label
          icon
          url
        }
      }
    }
  }
`;

export const GET_SHARE_WITH_FRIEND = /* GraphQL */ `
  query GetShareWithFriendBySlug($slug: String!) {
    getShareWithFriendEx(where: { slug: $slug }) {
      data {
        id
        modelId
        slug
        title
        description
        icon
        moreOffers
        comingSoon
        offers {
          id
          modelId
          title
          subTitle
          description
          image
          button {
            title
            helpText
            hoverText
            url
            icon
          }
        }
      }
    }
  }
`;

export const GET_PRODUCT_DETAIL = /* GraphQL */ `
  query GetProductDetailBySlug($slug: String!) {
    getProductDetail(where: { slug: $slug }) {
      data {
        id
        modelId
        slug
        title
        description
        image
        button {
          label
          icon
          url
        }
      }
    }
  }
`;

export const GET_SIMPLE_DATA_CARD = /* GraphQL */ `
  query GetSimpleDataCardBySlug($slug: String!) {
    getSimpleDataCardEx(where: { slug: $slug }) {
      data {
        modelId
        slug
        title
        subTitle
        toolTip {
          modelId
          slug
          title
          helpText
          image
        }
        button {
          modelId
          slug
          title
          helpText
          hoverText
          url
          icon
          iconAlt
        }
      }
    }
  }
`;

export const GET_NODE_HOSTING = /* GraphQL */ `
  query GetNodeHostingBySlug($slug: String!) {
    getNodeHostingEx(where: { slug: $slug }) {
      data {
        modelId
        slug
        title
        subTitle
        description
        image
        platforms {
          modelId
          slug
          title
          helpText
          hoverText
          url
          icon
          iconAlt
        }
      }
    }
  }
`;
