import { createContext, useState, useEffect, useContext } from 'react';
import { useRouter } from 'next/router';
import { Decimal } from 'decimal.js';
// Hooks
import { useAuth } from '@/hooks/useAuth';
// Utils
import {
  addToCart,
  clearUserCart,
  getCart,
  removeFromCart,
} from '@/resources/shopping-service.resource';
import { getCartTotals } from '@/utils/cart';
import { routes } from '@/routes/routes';

const Cart = createContext({});

export const useCartContext = () => useContext(Cart);

const initCartState = {
  cartError: { error: false, header: '', message: '' },
  cartItems: [],
  cartLoading: false,
  cartTotals: {
    cartQuantity: 0,
    cartSubtotal: 0,
    cartEthSubtotal: 0,
  },
  cartDisableCheckout: false,
};

const CartProvider = ({ children }) => {
  const router = useRouter();
  const { fetcher, status, authStatuses, isLoggingIn } = useAuth();

  const [
    { cartError, cartItems, cartLoading, cartTotals, cartDisableCheckout },
    setCartState,
  ] = useState(initCartState);
  const [showAddToModal, setShowModal] = useState(false);

  const handleSetCartState = (cartData) =>
    setCartState((prev) => ({ ...prev, ...cartData }));

  const clearCartError = () =>
    handleSetCartState({ cartError: { error: false, message: '' } });

  useEffect(() => {
    if (status !== authStatuses.SIGNED_IN) {
      setCartState(initCartState);
      return;
    }
    // Run on user login
    handleSetCartState({ cartLoading: true });
    getUserCart();
  }, [status]);

  const getUserCart = async () =>
    await fetcher(getCart())
      .then(({ cartItems }) => {
        const products = cartItems?.map((item) => {
          return {
            ...item,
            productEthPrice: item.productPriceInEth,
            subtotal: +Decimal.mul(item.productPrice, item.quantity).toString(),
            ethSubtotal: +Decimal.mul(item.productPriceInEth, item.quantity).toString(),
            productImage: item.product.iconImageURL,
            noLongerAvailable:
              (item.product.quantityLimited && item.product.quantity === 0) ||
              !item.product.active ||
              item.product.hidden,
          };
        });

        const hasInvalidProducts = products.some((product) => product.noLongerAvailable);
        handleSetCartState({
          cartError: initCartState.cartError,
          cartItems: products,
          cartLoading: false,
          cartTotals: getCartTotals(products),
          cartDisableCheckout: hasInvalidProducts,
        });
      })
      .catch(() => {
        handleSetCartState({
          cartError: {
            error: true,
            header: 'System Error.',
            message:
              'Your cart is unable to be previewed at this time. Please refresh the page or try again later.',
          },
          cartLoading: false,
        });
      });

  const addItemToCart = async ({ productId, quantity = 1, showModal = false }) => {
    handleSetCartState({ cartLoading: true });
    return await fetcher(addToCart({ productId, quantity }))
      .then(getUserCart)
      .then(() => showModal && setShowModal(true))
      .catch(() =>
        handleSetCartState({
          cartError: {
            error: true,
            header: 'System Error.',
            message: 'Unable to add item to cart, please try again.',
          },
          cartLoading: false,
        })
      );
  };

  const removeItemFromCart = async ({ productId, quantity }) => {
    handleSetCartState({ cartLoading: true });
    // If quantity is 0, find product in cartItems and remove all
    const productQuantity =
      quantity || cartItems.find((product) => product.productId === productId).quantity;

    await fetcher(removeFromCart({ productId, quantity: productQuantity }))
      .then(getUserCart)
      .catch(() => {
        const productName = cartItems.find(
          (product) => product.productId === productId
        ).name;
        handleSetCartState({
          cartError: {
            error: true,
            header: 'System Error.',
            message: `Your "${
              productName || 'item'
            }" is unable to be removed from your cart. Please refresh the page or try again later.`,
          },
          cartLoading: false,
        });
      });
  };

  const clearCart = async () =>
    await fetcher(clearUserCart())
      .then(() => setCartState(initCartState))
      .catch(() =>
        handleSetCartState({
          cartError: {
            error: true,
            header: 'System Error.',
            message:
              'Your items were unable to be removed from your cart. Please refresh the page or try again later.',
          },
          cartLoading: false,
        })
      );

  const handleCloseAddToModal = () => {
    setShowModal(false);
  };

  const createPendingTransaction = (productId, quantity) => {
    const productPath = router.asPath;
    localStorage.setItem(
      'cartItems',
      JSON.stringify({
        productId,
        quantity,
        path: productPath,
      })
    );

    return router.push({
      pathname: routes.login.path,
      query: { redirect: productPath, id: router.query.id, cart: true, showModal: true },
    });
  };

  useEffect(() => {
    const productInStorage = localStorage.getItem('cartItems');
    if (!productInStorage) return;

    if (isLoggingIn && status === authStatuses.SIGNED_IN) {
      addItemToCart({ ...JSON.parse(productInStorage) });
    }
  }, [status, router]);

  return (
    <Cart.Provider
      value={{
        addItemToCart,
        cartError,
        cartItems,
        cartLoading,
        cartTotals,
        cartDisableCheckout,
        clearCart,
        clearCartError,
        createPendingTransaction,
        handleCloseAddToModal,
        handleSetCartState,
        showAddToModal,
        removeItemFromCart,
      }}
    >
      {children}
    </Cart.Provider>
  );
};

export default CartProvider;
