import React from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { AppBar as MuiAppBar, Box, IconButton, Toolbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
// Components
import { CancelContinueButtons } from '@/common/index';
// Utils
import { routes } from '@/routes/routes';
import { brand } from '@/brand/brand';
import { colors } from '@/theme';

export default function AppBar({ layout, isDrawerOpen = false, handleToggleDrawer }) {
  const router = useRouter();
  const handleRouting = (path) => () => {
    router.push(`${path}`);
  };
  if (
    layout.variant === 'hide' ||
    (layout.device === 3 && layout.variant !== 'unauthorized')
  ) {
    return null;
  }

  return (
    <MuiAppBar
      elevation={2}
      sx={{
        zIndex: 3,
        height: 'var(--app-bar-height)',
        backgroundColor: colors.surfaces.layerTwo,
        padding: '0px',
        justifyContent: 'center',
      }}
    >
      <Toolbar
        disableGutters
        sx={{
          backgroundColor: 'transparent',
          paddingLeft: '12px',
          paddingRight: '12px',
          display: 'flex',
          alignItems: 'center',
          gap: '12px',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
          {layout.device < 3 && layout.variant !== 'simple' && (
            <IconButton color="primary" onClick={handleToggleDrawer}>
              {isDrawerOpen === false ? <MenuIcon /> : <CloseIcon />}
            </IconButton>
          )}
          <Link href="/home" passHref={true}>
            <a>
              <img
                src={
                  layout.device === 1 && layout.variant === 'unauthorized'
                    ? brand.brandLogoCompactLight
                    : brand.brandLogoHorizontalLight
                }
                alt={`${brand.brandName} Logo`}
                height="32"
                width="auto"
              />
            </a>
          </Link>
        </Box>
        {layout.variant === 'unauthorized' && (
          <CancelContinueButtons
            cancelText="Sign up"
            continueText="Log in"
            onCancel={handleRouting(routes.signup.path)}
            onContinue={handleRouting(routes.login.path)}
            size="small"
          />
        )}
      </Toolbar>
    </MuiAppBar>
  );
}
