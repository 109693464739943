import userCircle from '../assets/icons/fontawesome/user-circle-solid.svg';

export const manageAccountRoutes = {
  manageAccountProfile: {
    path: '/manage-account/profile',
    key: 'manage-account-profile',
    value: 'Manage Account',
    alias: 'Profile',
    icon: userCircle.src,
  },
  manageAccountChangePasscode: {
    path: '/manage-account/change-passcode',
    key: 'manage-account-change-passcode',
    value: 'Change Passcode',
  },
  manageAccountResetPassword: {
    path: '/manage-account/reset-password',
    key: 'manage-account-reset-password',
    value: 'Reset Password',
  },
  manageAccountTwoFactorAuth: {
    path: '/manage-account/two-factor-auth',
    key: 'manage-account-two-factor-auth',
    value: 'Two-Factor Authentication',
  },
};
