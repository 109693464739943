import { config } from '../config';
import axios from 'axios';

const currentVersion = '1.0';

export function getMemberPreferences() {
  return {
    method: 'GET',
    url: `${config.core.membershipServiceUrl}/v${currentVersion}/member-preferences`,
  };
}

export function createMemberPreference(key, value) {
  return {
    method: 'POST',
    url: `${config.core.membershipServiceUrl}/v${currentVersion}/member-preferences`,
    data: {
      name: key,
      value: value.toString(),
    },
  };
}
export function updateMemberPreference(key, value, recordId) {
  return {
    method: 'PUT',
    url: `${config.core.membershipServiceUrl}/v${currentVersion}/member-preferences/${recordId}`,
    data: {
      name: key,
      value: value.toString(),
    },
  };
}

export function getMemberDetails(token) {
  const headers = {
    'Content-Type': 'application/json',
    ...(token && { Authorization: `Bearer ${token}` }),
  };
  return axios.get(`${config.core.membershipServiceUrl}/v${currentVersion}/members`, {
    headers,
  });
}

export function updateMemberDetails({ userId, changedInfo }) {
  return {
    method: 'PUT',
    url: `${config.core.membershipServiceUrl}/v${currentVersion}/members/${userId}`,
    data: {
      id: userId,
      ...changedInfo,
    },
  };
}

// resendCode false means it is the first request, true is a request to send it again
export function updateMemberEmail({ newEmail, resendCode, confirmationLinkUrl }) {
  return {
    method: 'POST',
    url: `${config.core.membershipServiceUrl}/v${currentVersion}/members/update-email/${resendCode}`,
    data: {
      email: newEmail,
      confirmationLinkUrl,
    },
  };
}

// User logged in with auth token cancel request to change email
export function userCancelEmailChange() {
  return {
    method: 'POST',
    url: `${config.core.membershipServiceUrl}/v${currentVersion}/members/member-cancel-update-email`,
  };
}

// Cancel request to change email with email code
export function cancelEmailUpdateWithCode({ confirmationCode }) {
  return axios.put(
    `${config.core.membershipServiceUrl}/v${currentVersion}/members/cancel-email-update/${confirmationCode}`
  );
}

// Confirm request to change email
export function confirmEmailChange({ confirmationCode }) {
  return axios.put(
    `${config.core.membershipServiceUrl}/v${currentVersion}/members/confirm-email-update/${confirmationCode}`
  );
}

export function updateProfilePicture({ user, userId, formData }) {
  const { accessToken } = user;
  const headers = {
    'Content-Type': 'multipart/form-data',
    Authorization: `Bearer ${accessToken}`,
  };
  return axios.post(
    `${config.core.membershipServiceUrl}/v${currentVersion}/members/${userId}/profile-picture`,
    formData,
    { headers }
  );
}

export function deleteMemberProfilePicture(userId) {
  return {
    method: 'DELETE',
    url: `${config.core.membershipServiceUrl}/v${currentVersion}/members/${userId}/profile-picture`,
  };
}

export function getMemberReferrals() {
  return {
    method: 'GET',
    url: `${config.core.membershipServiceUrl}/v${currentVersion}/members/referrals`,
  };
}

export function getMemberExists({ name }) {
  return {
    method: 'GET',
    url: `${config.core.membershipServiceUrl}/v${currentVersion}/members/display-name-exists/${name}`,
  };
}

export function getMFACode() {
  return {
    method: 'GET',
    url: `${config.core.membershipServiceUrl}/v${currentVersion}/authenticator/generate-code?includeQRCode=true
    `,
  };
}

export function submitMFACode(value) {
  return {
    method: 'POST',
    url: `${config.core.membershipServiceUrl}/v${currentVersion}/authenticator/enable-authenticator
    `,
    data: {
      pin: value,
    },
  };
}

export function disableMFACode(value) {
  return {
    method: 'POST',
    url: `${config.core.membershipServiceUrl}/v${currentVersion}/authenticator/disable-authenticator
    `,
    data: {
      pin: value,
    },
  };
}

export function validateMFACode(email, value) {
  return {
    method: 'POST',
    url: `${config.core.membershipServiceUrl}/v${currentVersion}/authenticator/validate-pin
    `,
    data: {
      email: email,
      pin: value,
    },
  };
}

export function engageWaitlist({ firstName, lastName, email, phone, tags }) {
  return {
    method: 'POST',
    url: `${config.core.membershipServiceUrl}/v${currentVersion}/engage/waitlist`,
    data: {
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phone,
      tags: tags, // expects an array of strings
    },
  };
}

export function engagePassThrough({ firstName, lastName, email, phone, tags }) {
  return {
    method: 'POST',
    url: `${config.core.membershipServiceUrl}/v${currentVersion}/engage`,
    data: {
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phone,
      tags: tags, // expects an array
    },
  };
}

export function generateCoreBypassToken({ tokenType }) {
  return {
    method: 'POST',
    url: `${config.core.membershipServiceUrl}/v${currentVersion}/auth-token/bypass?tokenType=${tokenType}`,
  };
}

export function patchMemberDetails({ user, changedInfo }) {
  const { accessToken } = user;
  const headers = {
    'Content-Type': 'application/json-patch+json',
    Authorization: `Bearer ${accessToken}`,
  };
  return axios.patch(`${config.core.membershipServiceUrl}/v1.0/members`, changedInfo, {
    headers,
  });
}

export function updateShareNamePref() {
  return {
    method: 'PUT',
    url: `${config.core.membershipServiceUrl}/v${currentVersion}/member-opt-in-share-name`,
  };
}
