import { config } from '../config';
const currentVersion = '1.0';

export function getNftCollections() {
  return {
    method: 'GET',
    url: `${config.core.walletServiceUrl}/v${currentVersion}/nft-collections`,
  };
}

export function getBlockchainConfigurations() {
  return {
    method: 'GET',
    url: `${config.core.walletServiceUrl}/api/blockchain-configurations`,
  };
}

export function getNfts({ address, chainId, contractId }) {
  return {
    method: 'GET',
    url: `${config.core.walletServiceUrl}/v${currentVersion}/nfts/${chainId}/${contractId}/${address}`,
  };
}

export function getExternalNfts({ address, chainId }) {
  return {
    method: 'GET',
    url: `${config.core.walletServiceUrl}/api/nfts/external/${chainId}/${address}`,
  };
}

export function transferNft({
  chainId,
  contractAddress,
  gasFee,
  memberId,
  to,
  tokenId,
  walletPassword,
  isBatch = false,
  isCore = true,
}) {
  return {
    method: 'POST',
    url: `${config.core.walletServiceUrl}/v${currentVersion}/nfts/transfer${isBatch ? '/batch' : `?core=${isCore}`}`,
    data: {
      chainId,
      contractAddress,
      gasFee,
      memberId,
      to,
      walletPassword,
      ...(isBatch ? { tokenIds: tokenId } : { memberId, tokenId }),
    },
  };
}

export function getNftGasFee({
  contractAddress,
  chainId,
  tokenId,
  to,
  from,
  isBatch = false,
}) {
  const version = isBatch ? '1.0' : '2.0';
  return {
    method: 'POST',
    url: `${config.core.walletServiceUrl}/v${version}/nfts/transfer${isBatch ? '/batch' : ''}/gasfee`,
    data: {
      chainId,
      contractAddress,
      to,
      from,
      ...(isBatch ? { tokenIds: tokenId } : { tokenId }),
    },
  };
}

export function resendCouponCode({ chainId, contractAddress, tokenId }) {
  return {
    method: 'POST',
    url: `${config.core.walletServiceUrl}/api/nfts/activate/coupon/resend`,
    data: {
      chainId,
      contractAddress,
      tokenId,
    },
  };
}

export function activateCouponNFT({ chainId, contractAddress, tokenId, walletPassword }) {
  return {
    method: 'POST',
    url: `${config.core.walletServiceUrl}/api/nfts/activate`,
    data: {
      chainId,
      contractAddress,
      tokenId,
      walletPassword,
    },
  };
}

export function getCouponGasFee({
  chainId,
  contractAddress,
  tokenId,
  walletPassword = '',
  isCore = true,
}) {
  return {
    method: 'POST',
    url: `${config.core.walletServiceUrl}/api/nfts/activate/gas-fee?core=${isCore}`,
    data: {
      chainId,
      tokenId,
      contractAddress,
      walletPassword,
    },
  };
}

export function checkCouponActive({ chainId, contractAddress, tokenId }) {
  return {
    method: 'GET',
    url: `${config.core.walletServiceUrl}/api/nfts/activate/${chainId}/${contractAddress}/${tokenId}`,
  };
}
