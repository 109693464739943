export const TransferSolid = ({ className }) => {
  return (
    <svg
      id="Layer_2"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100.39"
    >
      <path d="M29.1,100.39v-10.21s39.31,0,39.31,0c0,0,10.58-.53,12.05-11.6v-13.52l-6.1-.04s.66,5.25-5.76,6.99l-39.5-.18,.04-10.25L0,80.95l29.1,19.45Z" />
      <path d="M70.9,0V10.21s-39.31,0-39.31,0c0,0-10.58,.53-12.05,11.6v13.52h6.06s-.62-5.22,5.8-6.96l39.5,.18-.04,10.25,29.15-19.36L70.9,0Z" />
      <path
        d="M49.83,33.28c-9.13,0-16.54,7.58-16.54,16.92s7.41,16.92,16.54,16.92,16.54-7.58,16.54-16.92-7.41-16.92-16.54-16.92Zm3.1,19.3c0,.29-.24,.53-.53,.53h-4.83c-.29,0-.53-.24-.53-.53v-4.86c0-.29,.24-.53,.53-.53h4.83c.29,0,.53,.24,.53,.53v4.86Z"
      />
    </svg>
  );
};
