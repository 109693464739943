import { useEffect, useState } from 'react';
import { Typography } from '@mui/material';

import PasswordStrengthBar from './PasswordStrengthBar';
import PasswordCriteria from './PasswordCriteria';
import styles from './password-validation.module.css';

import {
  getHasLength,
  getHasLowercase,
  getHasNoSpaces,
  getHasNumber,
  getHasSymbol,
  getPasswordStrength,
  getHasUppercase,
} from '@/utils/validation';

const initialPasswordState = {
  hasNoSpaces: {
    label: 'No Spaces',
    currentStatus: 'initial',
  },
  hasNumber: {
    label: '1 Number',
    currentStatus: 'initial',
  },
  hasUpperCase: {
    label: '1 UPPERCASE',
    currentStatus: 'initial',
  },
  hasLowerCase: {
    label: '1 lowercase',
    currentStatus: 'initial',
  },
  hasLength: {
    label: '8 characters',
    currentStatus: 'initial',
  },
  hasSymbol: {
    label: '1 symbol !@#$%^&*',
    currentStatus: 'initial',
  },
};

export default function PasswordValidation({
  password,
  isPasscode = false,
  showStrength = true,
}) {
  const [passwordState, setPasswordState] = useState(initialPasswordState);
  const [strength, setPasswordStrength] = useState(0);

  useEffect(() => {
    if (password.length === 0) {
      setPasswordState(initialPasswordState);
      return;
    }

    const upper = getHasUppercase({ password });
    const lower = getHasLowercase({ password });
    const symbol = getHasSymbol({ password });
    const number = getHasNumber({ password });
    const noSpace = getHasNoSpaces({ password });
    const length = getHasLength({ password, length: 8 });
    const currentStrength = getPasswordStrength({ password });

    setPasswordState((prevState) => ({
      ...prevState,
      hasNoSpaces: {
        ...prevState.hasNoSpaces,
        currentStatus: noSpace ? 'met' : 'unmet',
      },
      hasNumber: {
        ...prevState.hasNumber,
        currentStatus: number ? 'met' : 'unmet',
      },
      hasUpperCase: {
        ...prevState.hasUpperCase,
        currentStatus: upper ? 'met' : 'unmet',
      },
      hasLowerCase: {
        ...prevState.hasLowerCase,
        currentStatus: lower ? 'met' : 'unmet',
      },
      hasLength: {
        ...prevState.hasLength,
        currentStatus: length ? 'met' : 'unmet',
      },
      hasSymbol: {
        ...prevState.hasSymbol,
        currentStatus: symbol ? 'met' : 'unmet',
      },
    }));

    currentStrength > 0 ? setPasswordStrength(currentStrength) : setPasswordStrength(0);
  }, [password]);

  return (
    <div>
      <div data-core-qa="strengthTitleText" className={styles.labelText}>
        <Typography variant="h6">
          {`${isPasscode ? 'Passcode' : 'Password'} must include:`}
        </Typography>
      </div>
      <div className={styles.criteriaWrapper} data-core-qa="strengthCriteria">
        {Object.values(passwordState).map((criteria, i) => (
          <PasswordCriteria
            criteriaText={criteria.label}
            status={criteria.currentStatus}
            key={i}
            data-core-qa={`strength${i}`}
          />
        ))}
      </div>
      {showStrength ? (
        <div data-core-qa="strengthBar">
          {password.length > 0 ? (
            <PasswordStrengthBar strengthPercentage={strength} />
          ) : null}
        </div>
      ) : null}
    </div>
  );
}
