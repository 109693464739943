export const GET_REWARDS_PAGE = /* GraphQL */ `
  query GetRewardsPageEx {
    getRewardsPageEx(where: { slug: "rewards-page" }) {
      data {
        modelId
        slug
        title
        pageHeader {
          modelId
          slug
          title
          subTitle
        }
        lastDistCard {
          modelId
          slug
          title
          subTitle
          toolTip {
            modelId
            slug
            title
            helpText
          }
        }
        totalCoinCard {
          modelId
          slug
          title
          subTitle
          toolTip {
            modelId
            slug
            title
            helpText
          }
          button {
            modelId
            slug
            title
          }
        }
        totalPointsCard {
          modelId
          slug
          title
          subTitle
          toolTip {
            modelId
            slug
            title
            helpText
          }
        }
        bridgePopUp {
          modelId
          slug
          title
          description
          primaryButton {
            modelId
            slug
            title
          }
          secondaryButton {
            modelId
            slug
            title
          }
        }
        cardError {
          modelId
          slug
          title
          description
        }
        tableError {
          modelId
          slug
          title
          description
        }
        noTableData {
          modelId
          slug
          title
          description
        }
        rewardsHistoryTable {
          modelId
          slug
          title
          description
        }
      }
    }
  }
`;
