import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

// Hooks
import { useAuth } from '@/hooks/useAuth';
import { routes } from '@/routes/routes';

// Resources
import { linkUserToBrandAmbassador } from '@/resources/brand-ambassador-service.resource';

export function StrictlyPublicRoute({ children }) {
  const router = useRouter();
  const { status, authStatuses, fetcher } = useAuth();
  const [isLoggedOut, setIsLoggedOut] = useState(false);

  useEffect(() => {
    const handleRedirect = async () => {
      const { redirect } = router.query;

      if (redirect && redirect.includes('myambassador')) {
        const ambassadorId = redirect.match(/\d+/g)[0];
        sessionStorage.setItem('brandAmbassadorId', ambassadorId);
      }

      if (status === authStatuses.SIGNED_OUT || status === authStatuses.PENDING) {
        setIsLoggedOut(true);
      } else if (status === authStatuses.SIGNED_IN) {
        const brandAmbassadorId = sessionStorage.getItem('brandAmbassadorId');
        const isShopping = localStorage.getItem('cartItems');

        if (isShopping) {
          const shoppingData = JSON.parse(isShopping);
          const path = shoppingData.path?.split('?')[0];
          router.replace({
            pathname: path,
            query: { id: shoppingData.productId, showModal: true },
          });
        } else if (brandAmbassadorId) {
          try {
            await fetcher(linkUserToBrandAmbassador(brandAmbassadorId));
            sessionStorage.removeItem('brandAmbassadorId'); // Remove brandAmbassadorId from session storage after successful call
          } catch (error) {
            console.error('Error linking user to brand ambassador:', error);
          }
          router.push(routes.agreements.path);
        } else {
          router.push(routes.agreements.path);
        }
      }
    };

    handleRedirect();
  }, [router, status, authStatuses, fetcher]);

  if (isLoggedOut) {
    return children;
  } else {
    return null;
  }
}
