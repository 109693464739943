import { getCookie, setCookie } from 'cookies-next';
import { useRouter } from 'next/router';
// Hooks
import { useAuth } from './useAuth';
// Utils
import { createMemberPreference } from '@/resources/membership-service.resource';
import { brand } from '@/brand/brand';

export const useCookies = () => {
  const router = useRouter();
  const { fetcher, memberPreferences } = useAuth();

  // get cookie prefs set by brand site and save to db
  const handleGetPrefCookies = () => {
    const localCookie = getCookie(brand.cookiesPreferencesKey);
    const localPref = localCookie && JSON.parse(localCookie);

    if (memberPreferences.hasCookies && !localPref) {
      //if the user has preferences in the db, it is to be used as the source of truth and should always update the local browser
      return setCookie(
        brand.cookiesPreferencesKey,
        JSON.stringify({
          essential: true,
          analytics: memberPreferences.hasPref?.analytics?.value,
          functional: memberPreferences.hasPref?.functional?.value,
          marketing: memberPreferences.hasPref?.marketing?.value,
        }),
        { domain: brand.cookiesDomain }
      );
    }
    if (localPref && !memberPreferences.hasCookies) {
      Object.entries(localPref).forEach(([key, value]) => {
        fetcher(createMemberPreference(key, value)).catch((err) => console.error(err));
      });
    }
  };

  // save and use referral member id
  const handleSetReferral = () => {
    const sponsor = handleGetReferral();
    sponsor && setCookie('ReferringMember', sponsor);
    sponsor && localStorage.setItem('ReferringMember', sponsor);
    return router.pathname.includes('store')
      ? router.push('/store/all')
      : router.push(router.pathname);
  };

  const handleGetReferral = () =>
    router.query?.sponsor ||
    localStorage.getItem('ReferringMember') ||
    getCookie('ReferringMember');

  return {
    handleGetPrefCookies,
    handleGetReferral,
    handleSetReferral,
  };
};
