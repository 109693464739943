import { Decimal } from 'decimal.js';
// Types
import { CartItem } from '@/types/CartTypes';

interface CartTotals {
  cartSubtotal: number;
  cartQuantity: number;
  cartEthSubtotal: number;
}

export const getCartTotals = (arr: CartItem[]): CartTotals =>
  arr?.reduce(
    (acc, { ethSubtotal, quantity, subtotal }) => {
      return {
        cartSubtotal: +Decimal.add(acc.cartSubtotal, subtotal).toString(),
        cartEthSubtotal: +Decimal.add(acc.cartEthSubtotal, ethSubtotal).toString(),
        cartQuantity: +Decimal.add(acc.cartQuantity, quantity).toString(),
      };
    },
    { cartSubtotal: 0, cartQuantity: 0, cartEthSubtotal: 0 }
  );
