import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { useAuth } from '@/hooks/useAuth';
import { routes } from '@/routes/routes';

export function ProtectedRoute({ children }) {
  const router = useRouter();
  const { status, authStatuses } = useAuth();
  const [isVerified, setIsVerified] = useState(false);

  useEffect(() => {
    if (status === authStatuses.SIGNED_OUT) {
      router.push(routes.login.path);
    } else if (status === authStatuses.SIGNED_IN) {
      setIsVerified(true);
    }
  }, [router, status, authStatuses]);

  if (isVerified) {
    return children;
  } else {
    return null;
  }
}
