export const GET_HOME_PAGE = /* GraphQL */ `
  query GetHomePageEx {
    getHomePageEx(where: { slug: "home-page" }) {
      data {
        modelId
        slug
        title
        pageHeader {
          modelId
          slug
          title
          subTitle
        }
        inviteAndEarnHero {
          modelId
          slug
          title
          subTitle
          description
          terms
          image
          button {
            modelId
            slug
            title
            url
          }
        }
        setupWalletHero {
          modelId
          slug
          title
          subTitle
          description
          image
          button {
            modelId
            slug
            title
          }
        }
        shareWithFriends {
          modelId
          slug
          title
          description
          moreOffers
          comingSoon
          offers {
            id
            modelId
            slug
            title
            image
            content
            button {
              modelId
              slug
              title
              url
            }
          }
        }
        shareOfferPopup {
          modelId
          slug
          title
          altTitle
          subTitle
          altSubTitle
          listItem {
            id
            modelId
            slug
            title
          }
          primaryButton {
            modelId
            slug
            title
            helpText
            url
          }
          socialMedia {
            title
          }
        }
        referralTable {
          modelId
          slug
          title
          icon
          shareNameText
          hideNameText
          shareNameCheckboxLabel
          linkText
          noReferrals {
            title
            subTitle
            image
          }
          myReferrals {
            dateLabel
            nameLabel
            smartNodesLabel
            nodesLabel
          }
        }
      }
    }
  }
`;
