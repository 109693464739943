import React from 'react'

export const SMSBadge = ({className}) => {
  return (
    <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <g clipPath="url(#clip0_879_117681)">
    <path d="M23 46C35.7025 46 46 35.7025 46 23C46 10.2975 35.7025 0 23 0C10.2975 0 0 10.2975 0 23C0 35.7025 10.2975 46 23 46Z" fill="#EDEDED"/>
    <path d="M32.8472 35.844V28.8218H24.0713L32.8472 35.844Z" fill="#F33052"/>
    <path d="M32.1712 30.1875H13.8287C10.5728 30.1875 7.90625 27.5209 7.90625 24.265V17.4225C7.90625 14.1666 10.5728 11.5 13.8287 11.5H32.1712C35.4272 11.5 38.0938 14.1666 38.0938 17.4225V24.265C38.0938 27.5281 35.4272 30.1875 32.1712 30.1875Z" fill="#F33052"/>
    <path d="M13.2175 22.8275C13.5554 23.0503 14.1519 23.2803 14.7269 23.2803C15.5607 23.2803 15.9488 22.8635 15.9488 22.346C15.9488 21.7997 15.6254 21.4978 14.7772 21.1888C13.6488 20.7863 13.1097 20.161 13.1097 19.4063C13.1097 18.3928 13.9291 17.5591 15.2804 17.5591C15.92 17.5591 16.4735 17.7388 16.8257 17.9472L16.5382 18.781C16.2938 18.6228 15.8338 18.4144 15.2516 18.4144C14.576 18.4144 14.195 18.8025 14.195 19.2697C14.195 19.7872 14.5688 20.0244 15.3882 20.3335C16.4807 20.7503 17.0413 21.2966 17.0413 22.231C17.0413 23.3378 16.186 24.1141 14.691 24.1141C14.001 24.1141 13.3613 23.9416 12.9229 23.6828L13.2175 22.8275Z" fill="white"/>
    <path d="M18.5288 19.4063C18.5288 18.7594 18.5145 18.2203 18.4785 17.7028H19.4776L19.5279 18.7163H19.5638C19.916 18.1197 20.4982 17.5591 21.5404 17.5591C22.3957 17.5591 23.0498 18.0766 23.3229 18.8241H23.3516C23.5457 18.4719 23.7901 18.1988 24.056 18.0047C24.437 17.7172 24.8466 17.5591 25.4504 17.5591C26.2841 17.5591 27.5204 18.1053 27.5204 20.2903V23.9991H26.3991V20.4341C26.3991 19.2266 25.9535 18.4935 25.0335 18.4935C24.3866 18.4935 23.8763 18.975 23.6823 19.5356C23.632 19.6938 23.5888 19.9022 23.5888 20.1106V23.9991H22.4676V20.2256C22.4676 19.2266 22.0291 18.4935 21.1523 18.4935C20.4407 18.4935 19.916 19.0685 19.7363 19.6363C19.6716 19.8088 19.6429 20.0028 19.6429 20.1969V23.9919H18.5216V19.4063H18.5288Z" fill="white"/>
    <path d="M29.2244 22.8275C29.5622 23.0503 30.1587 23.2803 30.7337 23.2803C31.5675 23.2803 31.9556 22.8635 31.9556 22.346C31.9556 21.7997 31.6322 21.4978 30.7841 21.1888C29.6556 20.7863 29.1166 20.161 29.1166 19.4063C29.1166 18.3928 29.9359 17.5591 31.2872 17.5591C31.9269 17.5591 32.4803 17.7388 32.8325 17.9472L32.545 18.781C32.3006 18.6228 31.8406 18.4144 31.2584 18.4144C30.5828 18.4144 30.2019 18.8025 30.2019 19.2697C30.2019 19.7872 30.5756 20.0244 31.395 20.3335C32.4875 20.7503 33.0481 21.2966 33.0481 22.231C33.0481 23.3378 32.1928 24.1141 30.6978 24.1141C30.0078 24.1141 29.3681 23.9416 28.9297 23.6828L29.2244 22.8275Z" fill="white"/>
    </g>
    <defs>
    <clipPath id="clip0_879_117681">
    <rect width="46" height="46" fill="white"/>
    </clipPath>
    </defs>
    </svg>
    

  )
}
