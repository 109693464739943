import { handleFetch } from '../api';
// Queries
import { pageQueries } from './pages';
import { modelQueries } from './models';

const { components, experienceFlow, tables, uiElements, urls } = modelQueries;

const buildQueries = (queries) => {
  return Object.keys(queries).reduce((acc, key) => {
    acc[key] = (slug) => handleFetch(queries[key], slug);
    return acc;
  }, {});
};

const Components = buildQueries(components);
const ExpFlow = buildQueries(experienceFlow);
const Tables = buildQueries(tables);
const UiElements = buildQueries(uiElements);
const Pages = buildQueries(pageQueries);
const URLs = buildQueries(urls);

export { Components, ExpFlow, Pages, Tables, UiElements, URLs };
