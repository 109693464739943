import { createContext, useState, useEffect, useContext } from 'react';
// Hooks
import { useAuth } from '@/hooks/useAuth';
// Utils
import { config } from 'config';
import {
  getAvailableProducts,
  getProductByID,
} from '@/resources/shopping-service.resource';

export const StoreContext = createContext({});

export const useStoreContext = () => useContext(StoreContext);

const initStoreState = {
  storeError: { error: false, message: '' },
  storeItems: [],
  storeLoading: true,
};

const initSearchState = {
  search: '',
  filteredProducts: [],
  searchError: false,
};

const StoreProvider = ({ children }) => {
  const { fetcher, memberData } = useAuth();

  const [{ storeError, storeItems, storeLoading }, setStoreState] =
    useState(initStoreState);
  const [{ search, filteredProducts, searchError }, setSearchState] =
    useState(initSearchState);
  const [view, setView] = useState('grid');
  const [smartNode, setSmartNode] = useState({});
  const [liteNode, setLiteNode] = useState({});

  const handleSetStoreState = (storeData) =>
    setStoreState((prev) => ({ ...prev, ...storeData }));

  const handleSetSearchState = (searchData) =>
    setSearchState((prev) => ({ ...prev, ...searchData }));

  const resetSearch = () => handleSetSearchState(initSearchState);

  useEffect(() => {
    handleGetProducts();
  }, [memberData]);

  const filterAvailableProducts = (products) =>
    products.filter((product) => {
      const isOutOfStock = product.quantityLimited && product.quantity <= 0;
      if (memberData?.canPurchaseNodes) {
        return !isOutOfStock;
      } else return product.isAvailableInApp && !isOutOfStock;
    });

  const handleGetProducts = async () => {
    return fetcher(getAvailableProducts())
      .then((products) => {
        const activeProducts = products.filter((product) => {
          if (product.id === config.brand.smartNodeId) setSmartNode(product);
          if (product.id === config.brand.liteNodeId) setLiteNode(product);
          return product.active && !product.hidden;
        });
        const availableProducts = filterAvailableProducts(activeProducts);
        const unavailableProducts = activeProducts.filter(
          (product) => !availableProducts.includes(product)
        );
        handleSetStoreState({
          storeItems: [...availableProducts, ...unavailableProducts],
          storeLoading: false,
        });
      })
      .catch(() =>
        handleSetStoreState({
          storeError: { error: true, message: 'Error getting products' },
          storeLoading: false,
        })
      );
  };

  const getProduct = async (productId) => {
    return fetcher(getProductByID({ productId })).catch(() =>
      handleSetStoreState({
        storeError: { error: true, message: `Error getting product ${productId}` },
      })
    );
  };

  return (
    <StoreContext.Provider
      value={{
        filteredProducts,
        getProduct,
        handleSetSearchState,
        liteNode,
        resetSearch,
        search,
        searchError,
        setView,
        smartNode,
        storeError,
        storeItems,
        storeLoading,
        view,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

export default StoreProvider;
