import dashboardIcon from '@/icons/fontawesome/chart-line-solid.svg';
import walletIcon from '@/icons/fontawesome/wallet-solid.svg';
import rewardsIcon from '@/icons/fontawesome/medal-solid.svg';
import nodesIcon from '@/icons/fontawesome/circle-nodes-solid.svg';
import apple from '@/icons/logos/apple.svg';
import linux from '@/icons/logos/linux.svg';
import vps from '@/icons/fontawesome/display-solid.svg';

const nodesSetupRoutes = {
  mac: {
    path: '/dashboard/nodes/mac',
    key: 'mac',
    value: 'macOS',
    icon: apple.src,
  },
  linux: {
    path: '/dashboard/nodes/linux',
    key: 'linux',
    value: 'Linux',
    icon: linux.src,
  },
  vps: {
    path: '/dashboard/nodes/vps',
    key: 'vps',
    value: 'VPS',
    icon: vps.src,
  },
};

export const dashboardRoutes = {
  ...nodesSetupRoutes,
  dashboard: {
    path: '/dashboard',
    key: 'dashboard',
    value: 'Dashboard',
    icon: dashboardIcon.src,
  },
  nodes: {
    path: '/dashboard/nodes',
    key: 'nodes',
    value: 'Nodes',
    icon: nodesIcon.src,
  },
  brandAmbassador: {
    path: '/dashboard/brand-ambassador',
    key: 'brand-ambassador',
    value: 'Brand Ambassador',
  },
  rewards: {
    path: '/dashboard/rewards',
    key: 'rewards',
    value: 'Rewards',
    icon: rewardsIcon.src,
  },
  wallet: {
    path: '/dashboard/wallet',
    key: 'wallet',
    value: 'Wallet',
    icon: walletIcon.src,
  },
  walletMigration: {
    path: '/dashboard/wallet/wallet-migration',
    key: 'wallet-migration',
    value: 'Wallet Migration',
  },
  forgotMnemonic: {
    path: '/dashboard/wallet/forgot-mnemonic',
    key: 'forgot-mnemonic',
    value: 'Forgot Mnemonic',
  },
  setupWallet: {
    path: '/dashboard/wallet/setup-wallet',
    key: 'setup-wallet',
    value: 'Setup wallet',
  },
};
