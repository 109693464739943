import { useMemo, useEffect, useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { getMinuteSecondDisplay } from '@/utils/timeUtils';

import { useIsMobile } from '@/hooks/useIsMobile';
import { colors } from '@/theme';
export default function CircleTimer({
  timerStartDate,
  timerEndDate,
  timerEndedCallback,
  children,
}) {
  const isMobile = useIsMobile(520);

  const timeInSeconds = useMemo(() => {
    const timeInMs = timerEndDate.getTime() - timerStartDate.getTime();
    return Math.ceil(timeInMs / 1000);
  }, [timerStartDate, timerEndDate]);

  const [displayTime, setDisplayTime] = useState(getMinuteSecondDisplay(timeInSeconds));
  const [percentTimeElapsed, setPercentTimeElapsed] = useState(0.01);

  let countDownInterval;

  useEffect(() => {
    function cleanUpInterval() {
      if (countDownInterval) {
        clearInterval(countDownInterval);
      }
    }

    const updateTimerDisplay = () => {
      const msLeft = timerEndDate.getTime() - Date.now();
      const secondsLeft = Math.ceil(msLeft / 1000);
      const minuteSecondDisplay = getMinuteSecondDisplay(secondsLeft);
      const percentTimeRemaining = (secondsLeft / timeInSeconds) * 100;
      const percentTimeElapsed = 100 - percentTimeRemaining || 0.01;
      setDisplayTime(minuteSecondDisplay);
      setPercentTimeElapsed(percentTimeElapsed);

      if (msLeft <= 0) {
        timerEndedCallback();
        cleanUpInterval();
      }
    };

    updateTimerDisplay();
    countDownInterval = setInterval(updateTimerDisplay, 1000);

    return cleanUpInterval;
  }, []);

  return (
    <div>
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress
          size={isMobile ? '125px' : '192px'}
          variant="determinate"
          value={100}
          sx={{
            color: colors.surfaces.layerTwoBorder,
          }}
        />
        <CircularProgress
          size={isMobile ? '125px' : '192px'}
          variant="determinate"
          value={percentTimeElapsed}
          sx={{
            position: 'absolute',
            left: 0,
            [`& .MuiCircularProgress-circle`]: {
              strokeLinecap: 'round',
            },
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h3" color={colors.grey[800]}>
            {displayTime}
          </Typography>
          <Box
            sx={{
              textAlign: 'center',
            }}
          >
            {children}
          </Box>
        </Box>
      </Box>
    </div>
  );
}
