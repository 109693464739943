import { useMemo, useState, useEffect } from 'react';
import Link from 'next/link';
import { Box, Drawer as MuiDrawer, List, Toolbar } from '@mui/material';
// Components
import { NavigationItem, AccountFooter } from './components';
// Hooks
import { useAppInfo } from '@/providers/AppInfoProvider';
import { useFeatureFlags } from '@/providers/FeatureFlagProvider';
// Utils
import { authRoutes, noAuthRoutes, accountRoutes } from '@/utils/navigation';
import { useAuth } from '@/hooks/useAuth';
import { brand } from '@/brand/brand';
import { config } from 'config';
// Styles & Assets
import { colors } from '@/theme';

function NavigationList({ items, handleToggleDrawer }) {
  return (
    <Box
      sx={{
        padding: '8px',
        overflowY: 'auto',
        width: '100%',
      }}
    >
      <List component="nav" sx={{ flexShrink: 1, flexGrow: 0 }}>
        {items.map((item, index) => (
          <NavigationItem
            key={index}
            item={item}
            handleToggleDrawer={handleToggleDrawer}
          />
        ))}
      </List>
    </Box>
  );
}

export default function Drawer({ layout, isDrawerOpen, handleToggleDrawer }) {
  const { navBadges } = useAppInfo();
  const [externalRoutes, setExternalRoutes] = useState([]);
  const { memberData } = useAuth();
  const { featureFlags } = useFeatureFlags();

  const isAmbassadorAndFeatureEnabled =
    memberData?.isBrandAmbassador && featureFlags['BrandAmbassador.Enabled'];
  const authorizedRoutes = useMemo(
    () => authRoutes(navBadges, isAmbassadorAndFeatureEnabled),
    [navBadges, isAmbassadorAndFeatureEnabled]
  );

  useEffect(() => {
    if (config.brand.externalRoutes) {
      const routes = JSON.parse(config.brand.externalRoutes);
      setExternalRoutes(routes);
    }
  }, []);

  if (layout.variant === 'hide') {
    return null;
  }

  return (
    <MuiDrawer
      variant={layout.device < 3 ? 'temporary' : 'permanent'}
      open={layout.device < 3 ? isDrawerOpen : true}
      onClose={handleToggleDrawer}
      sx={{
        zIndex: 2,
        width: 'var(--drawer-width)',
        height: '100dvh',
        padding: '0px',
        '& .MuiDrawer-paper': {
          width: 'var(--drawer-width)',
          padding: '0px',
          boxSizing: 'border-box',
          borderRightColor:
            layout.variant === 'simple'
              ? 'transparent'
              : colors.surfaces.layerTwoDividers,
          backgroundColor:
            layout.variant === 'simple'
              ? colors.surfaces.layerOne
              : colors.surfaces.layerTwo,
        },
      }}
    >
      <Toolbar
        disableGutters
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          height: '100%',
          width: '100%',
          overflowX: 'hidden',
        }}
      >
        {layout.device === 3 && layout.variant !== 'unauthorized' ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              padding: '32px 0',
            }}
          >
            <Link passHref href="/home">
              <a>
                <img
                  src={brand.brandLogoHorizontalLight}
                  alt={`${brand.brandName} Logo`}
                  height="32"
                  width="auto"
                />
              </a>
            </Link>
          </Box>
        ) : (
          <Box
            sx={{
              height: 'var(--app-bar-height)',
              width: '100%',
              flexShrink: 0,
            }}
          />
        )}
        <Box
          sx={{
            overflowY: 'auto',
            overflowX: 'hidden',
            scrollbarGutter: 'stable',
            flex: '1',
            width: '100%',
          }}
        >
          {layout.variant !== 'simple' && (
            <NavigationList
              items={
                layout.variant !== 'unauthorized'
                  ? [...authorizedRoutes, ...externalRoutes]
                  : noAuthRoutes
              }
              handleToggleDrawer={handleToggleDrawer}
            />
          )}
        </Box>
        {layout.variant !== 'unauthorized' && layout.variant !== 'simple' && (
          <AccountFooter
            accountRoutes={accountRoutes}
            handleToggleDrawer={handleToggleDrawer}
          />
        )}
      </Toolbar>
    </MuiDrawer>
  );
}
