import { useState } from 'react';
import { Tooltip } from '@mui/material';
import { CopySolid } from '@/icons/iconComponents';
// Styles
import styles from './copy-to-clipboard.module.css';

export default function CopyToClipboard({ copyText }) {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(copyText)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1600);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <Tooltip title={isCopied ? 'Copied' : ''}>
      <button
        className={styles.copy}
        onClick={handleCopyClick}
        data-core-qa="CopyToClipboard"
      >
        <div className={styles.container}>
          <p className={styles.value} data-core-qa="CopyToClipboard_value">
            {copyText}
          </p>
        </div>
        <div className={styles.iconBox}>
          <CopySolid className={styles.iconBox} />
        </div>
      </button>
    </Tooltip>
  );
}
