import { Box } from '@mui/material';
import { ReactNode } from 'react';
import Typography from '@mui/material/Typography';

interface Props {
  header: string | null;
  body: string | null;
  children?: ReactNode;
}

export default function AlertBody({ header, body, children }: Props) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
      }}
    >
      <Typography variant="h5">{header}</Typography>
      <Typography variant="body2">{body}</Typography>
      <>{children}</>
    </Box>
  );
}
