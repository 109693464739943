export const TriangleExclamation = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 24 21"      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M23.7323 18.0332L13.7414 0.983628C13.3573 0.327876 12.6828 0 12.0083 0C11.3339 0 10.6594 0.327876 10.2331 0.983628L0.246965 18.0332C-0.478578 19.34 0.478867 20.9841 2.01937 20.9841H22.0011C23.5355 20.9841 24.4957 19.3447 23.7323 18.0332ZM2.48027 18.7358L11.9662 2.47312L21.5355 18.7358H2.48027ZM12.0083 14.2907C11.1952 14.2907 10.5357 14.9502 10.5357 15.7633C10.5357 16.5765 11.1966 17.236 12.0102 17.236C12.8238 17.236 13.481 16.5765 13.481 15.7633C13.4791 14.9511 12.8234 14.2907 12.0083 14.2907ZM10.8842 7.11959V11.6162C10.8842 12.2391 11.3901 12.7403 12.0083 12.7403C12.6266 12.7403 13.1325 12.2368 13.1325 11.6162V7.11959C13.1325 6.50131 12.6313 5.99545 12.0083 5.99545C11.3854 5.99545 10.8842 6.50131 10.8842 7.11959Z" />
    </svg>
  );
};
