import { Box, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

import { colors } from '@/theme';
export default function PasswordCriteria({ criteriaText, status, ...rest }) {
  const statusIcon = (status) =>
    ({
      met: <CheckCircleIcon sx={{ width: '20px' }} color="success" />,
      unmet: <CancelOutlinedIcon sx={{ width: '20px' }} color="error" />,
      initial: <CircleIcon sx={{ width: '10px' }} color="grey" />,
    }[status]);

  const darkText = status === 'met' || status === 'unmet';

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
      }}
    >
      <div {...rest}>{statusIcon(status)}</div>
      <Typography
        variant="body3"
        color={darkText ? colors.grey[600] : colors.grey[500]}
        sx={{ marginBottom: '6px' }}
      >
        {criteriaText}
      </Typography>
    </Box>
  );
}
