import { useEffect, useState } from 'react';
import { Box, Stepper, Step, StepLabel, Divider, Typography } from '@mui/material';
import { colors } from '@/theme';
export default function ProgressStepper({ steps, view = 'bar' }) {
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    let updatedStep = steps.findIndex((step) => step.status === 'active');
    if (updatedStep === -1) {
      updatedStep = steps.length - 1;
    }
    setCurrentStep(updatedStep);
  }, [steps]);

  if (view === 'card') {
    return (
      <Box>
        <Box sx={{ width: '100%', display: 'flex', gap: '4px', marginBottom: '16px' }}>
          {steps.map((step, i) => {
            const dividerWidth = (100 - steps.length) / steps.length;
            const connectorColor =
              step.status === 'active' ? colors.primary.light : colors.primary.main;
            return (
              <Divider
                key={i}
                color={step.status === 'incomplete' ? colors.grey[400] : connectorColor}
                sx={{
                  width: `${dividerWidth}%`,
                  height: '5px',
                  borderRadius: '50px',
                }}
              />
            );
          })}
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              paddingTop: '1px',
              border: `2px solid ${colors.primary.main}`,
              borderRadius: '100px',
              color: colors.primary.main,
              width: '30px',
              height: '30px',
              boxShadow: `0 0 3px 3px  ${colors.primary.light}`,
            }}
          >
            {currentStep + 1}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography
              variant="body2"
              sx={{
                fontWeight: 300,
              }}
            >
              OUT OF {steps.length}
            </Typography>
            <Typography variant="body1">{steps[currentStep].label}</Typography>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <div>
      <Box sx={{ width: '100%' }}>
        <Stepper activeStep={currentStep}>
          {steps.map((step, i) => (
            <Step
              key={i}
              active={step.status === 'active'}
              completed={step.status === 'complete'}
              error={steps.error}
            >
              <StepLabel id={step.label}>{step.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    </div>
  );
}
