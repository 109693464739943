import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';
import 'react-toastify/dist/ReactToastify.css';
// Providers
import { AuthProvider } from '@/providers/AuthProvider';
import AppInfoProvider from '@/providers/AppInfoProvider';
import CartProvider from '@/providers/CartProvider';
import StoreProvider from '@/providers/StoreProvider';
import WalletProvider from '@/providers/WalletProvider';
import CheckoutProvider from '@/providers/CheckoutProvider';
import { CurrencyProvider } from '@/providers/CurrencyProvider';
import { FeatureFlagProvider } from '@/providers/FeatureFlagProvider';
import { WebinyProvider } from '@/providers/WebinyProvider';
// Routes
import { ProtectedRoute } from '@/components/Routes/ProtectedRoute';
import { StrictlyPublicRoute } from '@/components/Routes/StrictlyPublicRoute';
// Components
import { Layout } from '@/components/Layout/Layout';
// Themes
import myBrandTheme from '@/theme';
// Styles
import './_app.css';
import { ToastContainer as ToastContainerBase } from 'react-toastify';
import styled from '@emotion/styled';

const ToastContainer = styled(ToastContainerBase)`
  .Toastify__toast {
    padding: 0;
    min-height: auto;
    min-height: 32px;

    @media (max-width: 767px) {
      margin: 16px;
      border-radius: 4px;
    }
  }
  .Toastify__toast-body {
    padding: 0;
    color: white;
  }
`;

function Protector({ Component, pageProps }) {
  // nested layouts
  const getLayout = Component.getLayout || ((page) => page);

  const { protectedRoute, strictlyPublicRoute } = Component;

  if (protectedRoute) {
    return (
      <ProtectedRoute>
        <WebinyProvider value={pageProps}>{getLayout(<Component />)}</WebinyProvider>
      </ProtectedRoute>
    );
  } else if (strictlyPublicRoute) {
    return (
      <StrictlyPublicRoute>
        <WebinyProvider value={pageProps}>{getLayout(<Component />)}</WebinyProvider>
      </StrictlyPublicRoute>
    );
  } else {
    return (
      <>
        <WebinyProvider value={pageProps}>{getLayout(<Component />)}</WebinyProvider>
      </>
    );
  }
}

function App({ Component, pageProps }) {
  return (
    <FeatureFlagProvider>
      <AuthProvider>
        <CurrencyProvider>
          <WalletProvider>
            <CartProvider>
              <CheckoutProvider>
                <StoreProvider>
                  <AppInfoProvider>
                    <CssVarsProvider theme={myBrandTheme}>
                      <Layout Component={Component}>
                        <ToastContainer
                          autoClose={3000}
                          hideProgressBar
                          pauseOnHover={false}
                          position="bottom-center"
                          closeButton={false}
                          icon={false}
                        />
                        <Protector Component={Component} pageProps={pageProps} />
                      </Layout>
                    </CssVarsProvider>
                  </AppInfoProvider>
                </StoreProvider>
              </CheckoutProvider>
            </CartProvider>
          </WalletProvider>
        </CurrencyProvider>
      </AuthProvider>
    </FeatureFlagProvider>
  );
}

export default App;
