import { Alert } from '@mui/material';
import { colors } from '@/theme';

export default function UCBanner({ prevSiteURL = '' }) {
  return (
    <Alert
      variant="filled"
      severity="info"
      sx={{
        backgroundColor: colors.info.dark,
        borderRadius: 0,
        width: '100%',
        marginBottom: '0px',
        fontSize: '16px',
        '@media (max-width: 540px)': {
          fontSize: '12px',
        },
      }}
    >
      <span>{`Under Construction! For additional details, or to access previous information, please visit `}</span>
      <a
        href={prevSiteURL}
        target="_blank"
        rel="noreferrer"
        style={{ textDecoration: 'underline' }}
      >
        {`[BRAND WEBSITE]`}
      </a>
      <span>.</span>
    </Alert>
  );
}
