import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone'; // dependent on utc plugin
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.tz.setDefault();

const dateFormats = {
  short: 'MM/DD/YY h:mm A z',
  long: 'MMM D, YYYY h:mm A z',
};

export const formatDate = (timestamp, format = dateFormats.long) => {
  return dayjs.tz(timestamp).format(format);
};

export const getActivationExpirationDate = (timestamp, duration) => {
  const startDate = dayjs.tz(timestamp * 1000);
  const endDate = startDate.add(duration, 'days');
  const today = dayjs.tz();
  const remainingDays = endDate.diff(today, 'days');
  const remainingHours = endDate.diff(today, 'hours') % 24;
  const remainingMinutes = endDate.diff(today, 'minutes') % 60;
  return {
    activated: startDate.format(dateFormats.short),
    expires: endDate.format(dateFormats.short),
    timeRemaining: `${remainingDays}d ${remainingHours}h ${remainingMinutes}m`,
    isExpired: endDate.isBefore(today),
  };
};
