import { brand } from '@/brand/brand';
import { config } from '../config';
const currentVersion = '1.0';

const handleV2Endpoint = () => (brand.useV2Endpoints ? '2.0' : currentVersion);

export function getTotalPoints() {
  return {
    method: 'GET',
    url: `${config.core.membershipServiceUrl}/v${handleV2Endpoint()}/chain/total-points-sum`,
  };
}

export function getLatestReward() {
  return {
    method: 'GET',
    url: `${config.core.membershipServiceUrl}/v${handleV2Endpoint()}/chain/latest-reward`,
  };
}

export function getTotalRewards() {
  return {
    method: 'GET',
    url: `${config.core.membershipServiceUrl}/v${handleV2Endpoint()}/chain/total-rewards`,
  };
}

export function getCharterById({ charterKey }) {
  return {
    method: 'GET',
    url: `${config.core.membershipServiceUrl}/v${currentVersion}/chain/charter/${charterKey}`,
  };
}

export function getCharters() {
  return {
    method: 'GET',
    url: `${config.core.membershipServiceUrl}/v${handleV2Endpoint()}/chain/charters`,
  };
}

export function getUserVoteByCharter({ charterId }) {
  return {
    method: 'GET',
    url: `${config.core.membershipServiceUrl}/v${handleV2Endpoint()}/chain/charter/${charterId}/vote`,
  };
}
export function getAllUserVotes() {
  return {
    method: 'GET',
    url: `${config.core.membershipServiceUrl}/v${handleV2Endpoint()}/chain/votes`,
  };
}
export function createUserVote({ userId, charterId, voteFor }) {
  return {
    method: 'POST',
    url: `${config.core.membershipServiceUrl}/v${handleV2Endpoint()}/chain/votes`,
    data: {
      userId: userId,
      charterId: charterId,
      voteFor: voteFor,
    },
  };
}

export function getUserTokenBalance({ walletId }) {
  return {
    method: 'GET',
    url: `${config.core.walletServiceUrl}/v${currentVersion}/layer2/get-balance/${walletId}`,
  };
}
