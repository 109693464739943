const error = {
  error: false,
  header: '',
  message: '',
};

const checkout = {
  error: error,
  loading: false,
  invoice: {},
  etherscan: {},
};

const invoice = {
  invoiceItems: [],
  invoiceTotals: {},
};

const progressBarSteps = [
  {
    label: 'Order',
    status: 'active',
  },
  {
    label: 'Terms',
    status: 'incomplete',
  },
  {
    label: 'Submit',
    status: 'incomplete',
  },
  {
    label: 'Confirm',
    status: 'incomplete',
  },
];

const processing = {
  isPending: false,
  endTime: 0,
};

const walletExtensionConnectors = {
  metamask: 'MetaMask',
};

const coupons = {
  couponIds: [],
  loading: false,
  error: null,
  showBanner: false,
  couponTotalUsd: 0,
  smartNodeReqMet: false,
  smartNodeTitle: '',
};

export const initState = {
  error,
  checkout,
  invoice,
  progressBarSteps,
  processing,
  walletExtensionConnectors,
  coupons,
};

export const couponReducer = (state, action) => {
  switch (action.type) {
    case 'SET_STATE':
      return { ...state, ...action.payload };
    case 'SET_LOADING':
      return { ...state, loading: action.payload };
    case 'SHOW_BANNER':
      return { ...state, showBanner: action.payload };
    case 'SET_ERROR':
      return { ...state, error: action.payload };
    case 'CLEAR':
      return coupons;
    default:
      return state;
  }
};

// Error handling
export const handlePaymentError = (errorMessage, callback) => {
  const errMessage = errorMessage?.ErrorDetail ? errorMessage?.ErrorDetail : errorMessage;
  let header = 'System Error.';
  let message;
  if (errMessage.includes('already paid')) {
    message =
      'This invoice is no longer valid. Please return to your cart and try again.';
  } else if (errMessage.includes('expired')) {
    message = 'The invoice has expired. Please return to your cart and try again.';
  } else if (
    errorMessage?.ErrorCode === '70001' ||
    errMessage.includes('Decryption failed')
  ) {
    header = 'Incorrect Wallet Passcode';
    message = 'Please check your Wallet Passcode and try again.';
  } else {
    message =
      'Something went wrong and we are not able to process your payment. Please try again.';
  }
  callback(header, message);
};

// Time to submit payment
export const getTimerEndDate = (externalPay, createdAt) => {
  const mins = externalPay ? 15 : 10;
  const timeMinInMls = 1000 * 60 * mins;
  const startTimeInMls = new Date(createdAt).getTime();
  return new Date(startTimeInMls + timeMinInMls);
};
