import { authRoutes } from './authentication';
import { dashboardRoutes } from './dashboard';
import { manageAccountRoutes } from './manageAccount';

import referAFriendIcon from '@/icons/fontawesome/share-solid.svg';
import resetIcon from '@/icons/fontawesome/lock-solid.svg';
import purchaseIcon from '@/icons/fontawesome/store-solid.svg';
import keyIcon from '@/icons/fontawesome/key-solid.svg';
import gearIcon from '@/icons/fontawesome/gear.svg';
import votingIcon from '@/icons/fontawesome/vote-solid.svg';
import supportIcon from '@/icons/fontawesome/envelope-solid.svg';
import cartIcon from '@/icons/fontawesome/cart-shopping-solid.svg';
import bellIcon from '@/icons/fontawesome/bell-solid.svg';
import homeIcon from '@/icons/fontawesome/home-solid.svg';
import { brand } from '@/brand/brand';

export const routes = {
  ...authRoutes,
  ...dashboardRoutes,
  ...manageAccountRoutes,
  cart: {
    path: '/cart',
    key: 'cart',
    value: 'Cart',
    icon: cartIcon.src,
  },
  changePassword: {
    path: '/change-password',
    key: 'change-password',
    value: 'Change password',
    icon: resetIcon.src,
  },
  changeWalletPasscode: {
    path: '/change-wallet-passcode',
    key: 'change-wallet-passcode',
    value: 'Change Wallet Passcode',
    icon: keyIcon.src,
  },
  checkout: {
    path: '/checkout',
    key: 'checkout',
    value: 'Cart',
    icon: cartIcon.src,
  },
  forgotPassword: {
    path: '/forgot-password',
    key: 'forgot-password',
    value: 'Forgot Password',
  },
  home: {
    path: `${brand.loggedOutHomeRoute}`,
    key: 'home',
    value: 'Home',
    icon: brand.logoWithText,
  },
  loggedInHome: {
    path: `${brand.loggedInHomeRoute}`,
    key: 'home',
    value: 'Home',
    icon: homeIcon.src,
  },
  notifications: {
    path: '/notifications',
    key: 'notifications',
    value: 'Notifications',
    icon: bellIcon.src,
  },
  purchase: {
    path: '/purchase',
    key: 'purchase',
    value: 'Store',
    icon: purchaseIcon.src,
  },
  referFriend: {
    path: '/refer-a-friend',
    key: 'refer-a-friend',
    value: 'Refer a Friend',
    icon: referAFriendIcon.src,
  },
  resetPassword: {
    path: '/reset-password',
    key: 'reset-password',
    value: 'Reset password',
    icon: resetIcon.src,
  },
  settings: {
    path: '/settings',
    key: 'settings',
    value: 'Settings',
    icon: gearIcon.src,
  },
  staticSite: {
    path: `${brand.staticSite}`,
    key: 'static-site',
    value: 'Home',
    icon: homeIcon.src,
  },
  store: {
    path: '/store',
    key: 'store',
    value: 'Store',
    icon: purchaseIcon.src,
  },
  support: {
    path: brand.support.url ? `${brand.support.url}` : '/support',
    key: 'support',
    value: 'Support',
    icon: supportIcon.src,
    target: brand.support.target,
  },
  voting: {
    path: '/voting',
    key: 'voting',
    value: 'Voting',
    icon: votingIcon.src,
  },
};
