import {
  Box,
  Button,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
// Components
import LoadingSpinner from '../LoadingSpinner/Loading';
import { OnHoverToolTip } from '@/common/index';
// Utils
import { cssValue } from '@/utils/index';
// Styles
import { StyledCard } from './styled';

export default function InfoCard({
  amountOne, // single number to display
  amountTwo, // will display amountOne / amountTwo on the card
  amountText,
  children, // subtext under numbers - not footer
  dataTag, // unique identifier for individual card data tags - string with no spaces
  headingText,
  error,
  footerButtonOnClick,
  footerText,
  tooltipHeading,
  tooltipText,
  type,
  loading = false, // set default value for use with Rewards card
  showFooter = false,
}) {
  const typeStyle = (type) =>
    ({
      points: cssValue('--mui-palette-info-main'),
      rewards: cssValue('--mui-palette-info-main'),
      smart: cssValue('--mui-palette-primary-400'),
      lite: cssValue('--mui-palette-primary-300'),
    })[type];

  if (error) {
    return (
      <StyledCard>
        <CardHeader title={headingText} />
        <CardContent>
          <ErrorOutlineOutlinedIcon
            fontSize="large"
            color="error"
            data-core-qa={`cardErrorIcon${dataTag}`}
          />
          <Typography>{children}</Typography>
        </CardContent>
      </StyledCard>
    );
  }

  if (loading) {
    return (
      <StyledCard sx={{ minHeight: '200px' }}>
        <CardHeader title="Loading..." />
        <CardContent>
          <LoadingSpinner type="dark" />
        </CardContent>
      </StyledCard>
    );
  }

  return (
    <StyledCard>
      <CardHeader
        title={headingText}
        data-core-qa={`cardHeadingText${dataTag}`}
        subheader={
          <OnHoverToolTip
            headerText={tooltipHeading}
            hoverText={tooltipText}
            width={300}
          />
        }
      />
      <CardContent>
        <Box sx={{ display: 'flex', paddingTop: '16px', paddingBottom: '0px' }}>
          {amountTwo > 0 ? (
            <>
              <Typography
                variant="h2"
                data-core-qa={`cardAmountOne${dataTag}`}
                sx={{
                  color: typeStyle(type),
                }}
              >
                {Number(amountOne).toLocaleString()}
              </Typography>
              <Typography variant="h2">/</Typography>
              <Typography variant="h2" data-core-qa={`cardAmountTwo${dataTag}`}>
                {Number(amountTwo).toLocaleString()}
              </Typography>
            </>
          ) : (
            <>
              <Typography
                variant="h2"
                data-core-qa={`cardAmountOne${dataTag}`}
                sx={{
                  color: typeStyle(type),
                }}
              >
                {Number(amountOne).toLocaleString()}
              </Typography>
            </>
          )}
        </Box>
        <Typography
          sx={{ paddingBottom: '16px' }}
          variant="body2"
          data-core-qa={`cardAmountSubText${dataTag}`}
        >
          {amountText}
        </Typography>
      </CardContent>
      {showFooter ? (
        <CardActions sx={{ margin: '16px' }}>
          <Button
            variant="text"
            size="small"
            data-core-qa={`cardBodyText${dataTag}`}
            onClick={footerButtonOnClick}
          >
            <Typography
              variant="body2Dark"
              data-core-qa={`cardFooterText${dataTag}`}
              sx={{
                color: 'inherit',
                fontWeight: 500,
                textTransform: 'uppercase',
              }}
            >
              {footerText}
            </Typography>
            <ChevronRightIcon
              color="primary"
              data-core-qa={`cardFooterIcon${dataTag}`}
              sx={{ marginLeft: '8px', width: '20px' }}
            />
          </Button>
        </CardActions>
      ) : null}
    </StyledCard>
  );
}
