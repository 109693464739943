import { Box } from '@mui/material';
import { OnHoverToolTip } from '@/common/index';
import { greaterThanPrecision, formatAmount } from '@/utils/index';

const TokenAmount = ({
  amount,
  tipPlacement = 'top',
  token,
  precision,
  isNegative = false,
  align = 'flex-start',
}) => {
  const displayPrecision = token === 'USDC' ? 2 : precision || 6;
  const decimalPrecision = amount?.toString().split('.')[1]?.length || displayPrecision;

  return (
    <Box
      component="span"
      sx={{ display: 'flex', alignItems: 'center', justifyContent: align }}
    >
      {token !== 'USDC' && greaterThanPrecision(amount, displayPrecision) ? (
        <OnHoverToolTip
          hoverText={`${isNegative ? '-' : ''}${formatAmount(amount, decimalPrecision)}`}
          width={300}
          placement={tipPlacement}
          displayText={`${isNegative ? '-' : ''}${formatAmount(amount, displayPrecision)}... ${token}`}
        />
      ) : (
        `${isNegative ? '-' : ''}${formatAmount(amount, displayPrecision)} ${token}`
      )}
    </Box>
  );
};

export default TokenAmount;
