import React, { useState } from 'react';
import { Box, Chip, List, ListItemButton, ListItemText, Collapse } from '@mui/material';
import { useRouter } from 'next/router';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
// Hooks
import { useAuth } from '@/hooks/useAuth';
import { useFeatureFlags } from '@/providers/FeatureFlagProvider';
// Utils
import { colors } from '@/theme';

export default function NavigationItem({ item, handleToggleDrawer }) {
  const router = useRouter();
  const [open, setOpen] = useState(true);

  const { generateBypassToken } = useAuth();
  const { featureFlags } = useFeatureFlags();

  const handleClick = () => {
    setOpen((prevState) => !prevState);
  };

  const isActiveLink = router.pathname.includes(item.path);

  const handleItemClick = async (item) => {
    const href = item.path;

    if (href.includes('https://')) {
      featureFlags?.ExternalLinkBypass && (await generateBypassToken('authTemp'));

      window.open(href, item.target || '_self');
    } else {
      router.push(href);
    }
  };

  if (item.subitems) {
    return (
      <>
        <ListItemButton onClick={handleClick}>
          <Box
            sx={{
              width: '24px',
              height: '24px',
              marginRight: '16px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {item.icon}
          </Box>

          <ListItemText
            primary={item.value}
            primaryTypographyProps={{ color: colors.text.heading }}
            color={colors.text.heading}
          />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {item.subitems.map((subitem) => {
              const activeSubLink = router.pathname.includes(subitem.path);
              return (
                <div key={subitem.key} onClick={() => handleItemClick(subitem)}>
                  <ListItemButton
                    onClick={handleToggleDrawer}
                    sx={{
                      pl: 4,
                      borderLeft: activeSubLink ? `2px solid ${colors.primary.main}` : '',
                      backgroundColor: activeSubLink ? colors.surfaces.layerOne : '',
                    }}
                  >
                    <Box
                      sx={{
                        width: '24px',
                        height: '24px',
                        marginRight: '16px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      {subitem.icon}
                    </Box>
                    <ListItemText
                      primary={subitem.value}
                      primaryTypographyProps={{ color: colors.text.heading }}
                      id={item?.value}
                      data-core-qa-count={`${
                        item?.badgeContent ? item?.badgeContent : '0'
                      }`}
                    />
                    {subitem.badgeContent !== undefined && subitem.badgeContent ? (
                      <Chip
                        label={subitem.badgeContent}
                        color="error"
                        size="small"
                        sx={{ minWidth: '32px' }}
                      />
                    ) : null}
                  </ListItemButton>
                </div>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <div onClick={() => handleItemClick(item)}>
      <ListItemButton
        onClick={handleToggleDrawer}
        sx={{
          borderLeft: isActiveLink ? `2px solid ${colors.primary.main}` : '',
          backgroundColor: isActiveLink ? colors.surfaces.layerOne : '',
        }}
        data-core-qa={`navItem-${item?.value}`}
      >
        <Box
          sx={{
            width: '24px',
            height: '24px',
            marginRight: '16px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {typeof item.icon === 'string' ? (
            item.iconType === 'fontAwesome' ? (
              <i
                className={[`${item.icon} fa fa-sm`]}
                style={{ color: colors.text.heading }}
              ></i>
            ) : (
              <img src={item.icon} alt={item.value} height="24" width="auto" />
            )
          ) : (
            item.icon
          )}
        </Box>
        <ListItemText
          primary={item.value}
          primaryTypographyProps={{ color: colors.text.heading }}
          id={item?.value}
          data-core-qa-count={`${item?.badgeContent ? item?.badgeContent : '0'}`}
        />
        {item.badgeContent !== undefined && item.badgeContent && item.badgeContent > 0 ? (
          <Chip
            label={item.badgeContent}
            color="error"
            size="small"
            sx={{ minWidth: '32px' }}
          />
        ) : null}
      </ListItemButton>
    </div>
  );
}
